import { useCallback, useRef } from "react";
import { JClient } from "@jkuwait/client";
import type { Logger } from "@jkuwait/client";

export const useClient = (
  key: string,
  url: string,
  enableInsights?: boolean,
  enableWSFallback?: boolean,
  logger?: Logger
) => {
  const clientRef = useRef<JClient>();

  const getClient = useCallback(() => {
    if (clientRef.current) {
      return clientRef.current;
    }

    clientRef.current = JClient.getInstance(key, {
      enableInsights: enableInsights ?? true,
      enableWSFallback: enableWSFallback ?? true,
      logger,
    });
    clientRef.current.setBaseURL(url + "/api");
    // if (!clientRef.current.wsFallback) {
    //   clientRef.current.enableWSFallback();
    // }
    return clientRef.current;
  }, [key, logger, url, enableInsights, enableWSFallback, clientRef]);

  return {
    client: getClient(),
  };
};
