export const FemaleIcon = () => (
  <svg
    width="100px"
    height="100px"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M993.763 493.538v35c0 13.331-6.04 26.664-18.135 37.137-140.15 121.422-280.35 242.795-420.49 364.219-11.814 10.237-25.813 15.501-42.455 15.501v-35c16.644 0 30.641-5.264 42.455-15.501 140.141-121.424 280.336-242.802 420.49-364.217 12.095-10.475 18.135-23.803 18.135-37.139z"
      fill="#DE83A9"
    />
    <path
      d="M30.239 528.367v-3.5-1.75-3.5-3.5-1.75-3.5-3.5-1.75-3.5-3.5-1.75-3.5c0 14.707 6.701 29.313 19.037 40.019 138.449 120.064 277.05 239.996 415.562 360.02 13 11.26 28.74 16.466 47.852 16.994v35c-19.108-0.528-34.852-5.734-47.852-16.994C326.325 808.382 187.725 688.45 49.276 568.386c-12.337-10.705-19.037-25.312-19.037-40.019z"
      fill="#DE83A9"
    />
    <path
      d="M510.785 76.601c16.264 0 32.547 5.362 44.946 16.097a2689074.734 2689074.734 0 0 1 419.82 363.586c24.241 20.995 24.296 53.413 0.079 74.397-140.15 121.42-280.351 242.796-420.489 364.217-11.815 10.239-25.814 15.502-42.451 15.502-19.111-0.528-34.852-5.734-47.856-16.994-138.51-120.023-277.11-239.955-415.559-360.02-19.581-16.988-24.961-43.81-11.895-65.251 3.919-6.438 8.668-11.829 14.465-16.849C189.954 331.734 328.024 212.152 466.107 92.567c12.294-10.639 28.476-15.966 44.678-15.966z"
      fill="#EA659C"
    />
    <path
      d="M556.386 281.868c-44.763 14.067-88.806 51.289-88.715 107.391v-16.8c-0.087-56.103 43.952-93.323 88.715-107.391 15.25-4.792 31.097-6.528 47.233-6.667 0.829-0.015 1.641-0.02 2.733-0.02 57.222 0.092 100.808 29.933 120.68 64.101-0.005 5.546-0.087 11.091-0.093 16.64-19.917-34.1-63.452-63.847-120.587-63.941-1.093 0-1.904 0.006-2.733 0.02-16.137 0.138-31.983 1.875-47.233 6.667z"
      fill="#C92F6A"
    />
    <path
      d="M740.361 373.59v16.8c0.01-9.408-1.291-19.25-4.099-29.468a94.566 94.566 0 0 0-0.58-2.006 79.69 79.69 0 0 0-0.615-1.952c-13.475-40.841-61.759-81.676-128.712-81.785-1.094 0-1.906 0.006-2.734 0.02-8.254 0.072-16.429 0.56-24.489 1.662v-16.8c8.061-1.102 16.236-1.59 24.489-1.662 0.828-0.014 1.641-0.019 2.734-0.019 66.953 0.109 115.237 40.944 128.712 81.785a84.06 84.06 0 0 1 1.195 3.958c2.808 10.218 4.109 20.059 4.099 29.467M579.13 260.061v16.8c-7.699 1.053-15.293 2.665-22.744 5.007a150.205 150.205 0 0 0-6.736 2.294v-16.8a148.547 148.547 0 0 1 6.736-2.295c7.451-2.34 15.045-3.953 22.744-5.006"
      fill="#C92F6A"
    />
    <path
      d="M549.651 267.362v16.8c-9.851 3.621-19.563 8.362-28.665 14.178v-16.8c9.102-5.815 18.813-10.556 28.665-14.178M520.986 281.54v16.8c-10.719 6.849-20.59 15.188-28.849 24.941v-16.8c8.259-9.753 18.131-18.092 28.849-24.941"
      fill="#C92F6A"
    />
    <path
      d="M492.138 306.481v16.8c-13.61 16.074-22.843 35.989-24.273 59.408v-16.8c1.429-23.42 10.662-43.335 24.273-59.408M467.864 365.889v16.8c-0.132 2.16-0.198 4.35-0.194 6.569v-16.8c-0.004-2.22 0.062-4.409 0.194-6.569"
      fill="#C92F6A"
    />
    <path
      d="M735.067 356.966c-1.977-5.99-4.703-11.982-8.128-17.844 0.007-5.547 0.088-11.092 0.093-16.64 3.38 5.811 6.075 11.748 8.034 17.684 0.213 0.651 0.421 1.301 0.613 1.952 0.204 0.669 0.396 1.337 0.58 2.006 2.81 10.217 4.109 20.059 4.101 29.467v16.8c0.009-9.409-1.291-19.25-4.101-29.467a78.442 78.442 0 0 0-0.58-2.006 62.71 62.71 0 0 0-0.612-1.952z"
      fill="#C92F6A"
    />
    <path
      d="M740.361 373.59v16.8c0.01-9.408-1.291-19.25-4.099-29.468a94.566 94.566 0 0 0-0.58-2.006 79.69 79.69 0 0 0-0.615-1.952c-13.475-40.841-61.759-81.676-128.712-81.785-1.094 0-1.906 0.006-2.734 0.02-8.254 0.072-16.429 0.56-24.489 1.662v-16.8c8.061-1.102 16.236-1.59 24.489-1.662 0.828-0.014 1.641-0.019 2.734-0.019 66.953 0.109 115.237 40.944 128.712 81.785a84.06 84.06 0 0 1 1.195 3.958c2.808 10.218 4.109 20.059 4.099 29.467"
      fill="#C92F6A"
    />
    <path
      d="M512.841 457.867v16.8c-24.695-18.882-40.361-42.128-44.171-70.745a112.597 112.597 0 0 1-1-14.666v-16.8c0.007 4.754 0.332 9.644 1 14.667 3.81 28.617 19.474 51.863 44.171 70.744z"
      fill="#C92F6A"
    />
    <path
      d="M512.841 457.867v16.8c-24.695-18.882-40.361-42.128-44.171-70.745a112.597 112.597 0 0 1-1-14.666v-16.8c0.007 4.754 0.332 9.644 1 14.667 3.81 28.617 19.474 51.863 44.171 70.744"
      fill="#C92F6A"
    />
    <path
      d="M476.262 472.728v16.8c0-2.119-1.36-4.305-4.003-7.1-25.163-26.628-40.347-56.595-40.347-89.983v-16.8c0 33.389 15.185 63.354 40.347 89.983 2.642 2.795 4.003 4.981 4.003 7.1z"
      fill="#C92F6A"
    />
    <path
      d="M476.262 472.728v16.8c0-2.119-1.36-4.305-4.003-7.1-25.163-26.628-40.347-56.595-40.347-89.983v-16.8c0 33.389 15.185 63.354 40.347 89.983 2.642 2.795 4.003 4.981 4.003 7.1"
      fill="#C92F6A"
    />
    <path
      d="M740.364 373.589v16.8c-0.057 63.568-59.857 107.242-117.844 112.397v-16.8c57.987-5.156 117.788-48.829 117.844-112.397z"
      fill="#C92F6A"
    />
    <path
      d="M740.364 373.589v16.8a101.853 101.853 0 0 1-0.194 6.159v-16.8c0.126-2.033 0.191-4.084 0.194-6.159M740.17 379.747v16.8c-1.426 22.818-10.608 42.883-24.521 59.274v-16.8c13.912-16.391 23.095-36.456 24.521-59.274M715.649 439.021v16.8c-8.158 9.615-17.94 17.966-28.741 24.866v-16.8c10.8-6.901 20.583-15.25 28.741-24.866M686.908 463.889v16.8c-8.774 5.611-18.221 10.262-28.007 13.859v-16.8c9.787-3.597 19.233-8.249 28.007-13.859M658.901 477.749v16.8c-9.799 3.603-19.938 6.145-30.092 7.529v-16.8c10.154-1.385 20.294-3.927 30.092-7.529"
      fill="#C92F6A"
    />
    <path
      d="M628.81 485.278v16.8c-2.097 0.286-4.193 0.522-6.287 0.708v-16.8c2.092-0.186 4.19-0.423 6.287-0.708"
      fill="#C92F6A"
    />
    <path
      d="M622.521 485.986v16.8a200.6 200.6 0 0 1-2.004 0.17c-5.666 0.455-11.258 0.691-16.766 0.694-32.421-0.063-61.624-7.946-87.24-26.268a141.74 141.74 0 0 1-3.671-2.715v-16.8a132.056 132.056 0 0 0 3.671 2.717c25.618 18.32 54.819 26.204 87.24 26.267 5.508-0.003 11.1-0.239 16.766-0.695 0.667-0.054 1.336-0.11 2.004-0.17z"
      fill="#C92F6A"
    />
    <path
      d="M622.521 485.986v16.8a200.6 200.6 0 0 1-2.004 0.17c-5.666 0.455-11.258 0.691-16.766 0.694-32.421-0.063-61.624-7.946-87.24-26.268a141.74 141.74 0 0 1-3.671-2.715v-16.8a132.056 132.056 0 0 0 3.671 2.717c25.618 18.32 54.819 26.204 87.24 26.267 5.508-0.003 11.1-0.239 16.766-0.695 0.667-0.054 1.336-0.11 2.004-0.17"
      fill="#C92F6A"
    />
    <path
      d="M606.082 258.381c67.227 0.107 115.511 40.944 128.985 81.785a81.255 81.255 0 0 1 1.195 3.957c2.808 10.218 4.109 20.06 4.102 29.466-0.057 63.568-59.857 107.242-117.844 112.397-0.67 0.06-1.337 0.116-2.004 0.169-5.666 0.455-11.258 0.692-16.766 0.694-32.421-0.063-61.624-7.946-87.24-26.266a132.056 132.056 0 0 1-3.671-2.717c-24.696-18.882-40.361-42.127-44.171-70.743a112.6 112.6 0 0 1-1-14.667c-0.089-56.101 43.954-93.323 88.717-107.39 15.251-4.792 31.096-6.53 47.233-6.67 0.825-0.009 1.646-0.015 2.464-0.015z"
      fill="#F4B1CE"
    />
    <path
      d="M382.816 553.452v16.8c0-2.127-1.399-4.245-4.202-6.674-25.189-21.832-50.379-43.664-75.63-65.446-1.43-1.239-3.385-2.007-4.889-2.873v-16.8c1.504 0.865 3.458 1.634 4.889 2.873 25.252 21.782 50.441 43.614 75.63 65.445 2.803 2.43 4.202 4.549 4.202 6.675z"
      fill="#C92F6A"
    />
    <path
      d="M382.816 553.452v16.8c0-2.127-1.399-4.245-4.202-6.674-25.189-21.832-50.379-43.664-75.63-65.446-1.43-1.239-3.385-2.007-4.889-2.873v-16.8c1.504 0.865 3.458 1.634 4.889 2.873 25.252 21.782 50.441 43.614 75.63 65.445 2.803 2.43 4.202 4.549 4.202 6.675"
      fill="#C92F6A"
    />
    <path
      d="M775.83 372.297v16.8c0 51.032-32.844 96.954-76.369 120.761-28.104 15.372-58.637 24.241-91.709 24.938-1.39 0.029-2.78 0.044-4.165 0.044-30.363 0-59.097-7.028-85.659-20.262-3.483-1.735-6.416-2.815-9.295-2.818-3.359 0-6.647 1.472-10.653 5.086-17.701 15.969-36.035 31.412-54.108 47.069-2.634 2.287-3.958 4.386-3.958 6.479v-16.8c0-2.093 1.324-4.191 3.958-6.479 18.074-15.659 36.408-31.102 54.108-47.07 4.006-3.613 7.294-5.086 10.653-5.086 2.878 0.003 5.811 1.082 9.295 2.818 26.563 13.233 55.295 20.262 85.659 20.262 1.383 0 2.773-0.016 4.165-0.044 33.072-0.697 63.604-9.566 91.709-24.939 43.525-23.805 76.369-69.727 76.369-120.759z"
      fill="#C92F6A"
    />
    <path
      d="M775.83 372.297v16.8c0 2.476-0.078 4.938-0.229 7.389v-16.8c0.154-2.45 0.229-4.915 0.229-7.389M775.601 379.686v16.8c-1.794 28.912-14.003 55.878-32.467 77.707v-16.8c18.464-21.83 30.673-48.795 32.467-77.707M743.134 457.391v16.8a154.98 154.98 0 0 1-34.781 30.401v-16.8a154.973 154.973 0 0 0 34.781-30.401M708.353 487.793v16.8a153.414 153.414 0 0 1-8.892 5.265c-9.233 5.049-18.727 9.399-28.488 12.985v-16.8c9.762-3.587 19.255-7.937 28.488-12.986a152.373 152.373 0 0 0 8.892-5.264"
      fill="#C92F6A"
    />
    <path
      d="M670.974 506.044v16.8c-13.133 4.827-26.745 8.275-40.852 10.203v-16.8c14.106-1.928 27.719-5.375 40.852-10.203M630.122 516.247v16.8a194.98 194.98 0 0 1-22.37 1.75c-1.39 0.029-2.78 0.044-4.165 0.044-30.364 0-59.097-7.028-85.659-20.262-3.483-1.735-6.416-2.815-9.295-2.818-0.507 0-1.012 0.034-1.518 0.104v-16.8c0.506-0.07 1.01-0.104 1.518-0.104 2.878 0.003 5.811 1.082 9.295 2.818 26.563 13.233 55.294 20.262 85.659 20.262 1.383 0 2.773-0.016 4.165-0.044a194.98 194.98 0 0 0 22.37-1.75"
      fill="#C92F6A"
    />
    <path
      d="M507.118 495.067v16.8c-0.812 0.111-1.627 0.315-2.456 0.617v-16.8a11.708 11.708 0 0 1 2.456-0.617M504.662 495.685v16.8c-0.991 0.366-2.003 0.875-3.054 1.548v-16.8c1.051-0.673 2.063-1.183 3.054-1.548M501.608 497.232v16.8c-1.154 0.737-2.355 1.668-3.627 2.816-17.701 15.969-36.035 31.412-54.108 47.069a18.978 18.978 0 0 0-1.989 1.979v-16.8a18.692 18.692 0 0 1 1.989-1.979c18.074-15.658 36.408-31.101 54.108-47.069 1.274-1.148 2.473-2.079 3.627-2.816M441.884 549.098v16.8c-1.215 1.434-1.869 2.808-1.959 4.176v-16.8c0.09-1.37 0.743-2.744 1.959-4.176M439.925 553.273v16.8c-0.007 0.105-0.01 0.215-0.01 0.322v-16.8c0-0.106 0.003-0.216 0.01-0.322"
      fill="#C92F6A"
    />
    <path
      d="M523.212 628.585v16.8c-8.347 7.375-16.479 14.558-24.847 21.953v-16.8c8.367-7.394 16.5-14.579 24.847-21.953z"
      fill="#C92F6A"
    />
    <path
      d="M523.212 628.585v16.8c-8.347 7.375-16.479 14.558-24.847 21.953v-16.8c8.367-7.394 16.5-14.579 24.847-21.953"
      fill="#C92F6A"
    />
    <path
      d="M287.3 682.558v16.8c-8.427-7.255-16.583-14.272-25.13-21.631v-16.8c8.547 7.358 16.703 14.376 25.13 21.631z"
      fill="#C92F6A"
    />
    <path
      d="M287.3 682.558v16.8c-8.427-7.255-16.583-14.272-25.13-21.631v-16.8c8.547 7.358 16.703 14.376 25.13 21.631"
      fill="#C92F6A"
    />
    <path
      d="M498.365 650.538v16.8c-11.25-9.761-22.961-19.939-34.695-30.099-14.636-12.693-29.543-25.159-43.798-38.167-2.546-2.325-5.391-3.516-8.295-3.516-2.928 0-5.918 1.205-8.729 3.675-36.966 32.428-74.292 64.553-111.506 96.774-1.43 1.24-2.923 2.419-4.042 3.352v-16.8c1.119-0.933 2.611-2.111 4.042-3.352 37.215-32.222 74.541-64.347 111.506-96.774 2.811-2.47 5.801-3.676 8.729-3.676 2.904 0 5.749 1.191 8.295 3.517 14.254 13.008 29.162 25.473 43.798 38.167 11.733 10.16 23.446 20.338 34.695 30.099z"
      fill="#C92F6A"
    />
    <path
      d="M498.365 650.538v16.8c-11.25-9.761-22.961-19.939-34.695-30.099-14.636-12.693-29.543-25.159-43.798-38.167-2.546-2.325-5.391-3.516-8.295-3.516-0.486 0-0.973 0.032-1.461 0.101v-16.8c0.49-0.068 0.976-0.102 1.461-0.102 2.904 0 5.749 1.191 8.295 3.517 14.254 13.008 29.162 25.473 43.798 38.167 11.733 10.16 23.446 20.338 34.695 30.099M410.116 578.858v16.801c-0.813 0.109-1.631 0.315-2.443 0.613v-16.8a11.54 11.54 0 0 1 2.443-0.614M407.67 579.473v16.8c-0.904 0.331-1.808 0.782-2.696 1.351v-16.8a13.9 13.9 0 0 1 2.696-1.351"
      fill="#C92F6A"
    />
    <path
      d="M404.975 580.823v16.8a17.38 17.38 0 0 0-2.127 1.608c-36.966 32.43-74.292 64.553-111.506 96.774-1.43 1.24-2.923 2.419-4.042 3.352v-16.8c1.119-0.933 2.611-2.111 4.042-3.352 37.215-32.222 74.541-64.345 111.506-96.774a17.38 17.38 0 0 1 2.127-1.608"
      fill="#C92F6A"
    />
    <path
      d="M759.932 311.998c39.623 72.655-0.173 148.078-60.47 181.059-28.104 15.373-58.636 24.242-91.707 24.939-1.392 0.028-2.782 0.043-4.167 0.043-30.362 0-59.097-7.028-85.658-20.261-3.483-1.735-6.417-2.815-9.296-2.818-3.359 0-6.647 1.473-10.653 5.086-17.701 15.969-36.035 31.412-54.108 47.069-5.21 4.522-5.292 8.314-0.153 12.765 26.663 23.063 53.345 46.109 79.492 68.702-8.347 7.375-16.479 14.558-24.847 21.953-11.25-9.761-22.961-19.939-34.695-30.099-14.636-12.693-29.543-25.159-43.798-38.167-2.546-2.325-5.391-3.516-8.295-3.516-2.928 0-5.918 1.205-8.729 3.673-36.966 32.43-74.292 64.555-111.506 96.775-1.43 1.241-2.923 2.42-4.042 3.353-8.427-7.256-16.583-14.272-25.13-21.631 31.571-27.314 63.242-54.726 94.922-82.128 7.127-6.172 14.254-12.335 21.381-18.506 5.734-4.968 5.795-8.616 0.14-13.514-25.189-21.831-50.38-43.664-75.63-65.446-1.43-1.239-3.385-2.007-4.889-2.873 9.266-8.013 17.288-14.961 25.686-22.225 1.18 0.97 2.661 2.13 4.071 3.354 25.159 21.857 50.3 43.735 75.47 65.582 3.102 2.696 5.57 4.032 8.024 4.032 2.431 0 4.843-1.313 7.841-3.917a50020.44 50020.44 0 0 1 52.245-45.254c6.27-5.421 6.322-8.598 0.827-14.405-28.868-30.552-44.604-65.496-39.344-104.963 8.044-60.392 46.137-101.936 111.838-123.854 19.105-6.375 38.946-9.369 58.613-9.369 65.148 0.004 128.375 32.863 156.567 84.561zM622.521 485.986c57.986-5.154 117.786-48.829 117.841-112.396 0.01-9.408-1.291-19.25-4.099-29.467a94.566 94.566 0 0 0-0.58-2.006 79.69 79.69 0 0 0-0.615-1.952c-13.475-40.841-61.759-81.676-128.712-81.785-1.094 0-1.906 0.005-2.734 0.019-16.138 0.14-31.985 1.876-47.234 6.668-44.761 14.067-88.804 51.289-88.715 107.391 0.005 4.754 0.332 9.645 0.999 14.667 3.809 28.615 19.475 51.864 44.17 70.743 0.99 0.759 1.986 1.5 2.988 2.225 0.229 0.164 0.454 0.33 0.683 0.492 25.621 18.32 54.818 26.204 86.717 26.266a213.546 213.546 0 0 0 17.289-0.693c0.664-0.056 1.334-0.112 2.002-0.172"
      fill="#DF3D82"
    />
  </svg>
);
