import { ComponentProps, useEffect, useState } from "react";
import ReactMarkdown from 'react-markdown';

import remarkGfm from 'remark-gfm';
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useClient } from "../hooks/useClient/useClient";


const allowedMarkups: Array<keyof JSX.IntrinsicElements | 'emoji' | 'mention'> = [
  'html',
  'text',
  'br',
  'p',
  'em',
  'strong',
  'a',
  'ol',
  'ul',
  'li',
  'code',
  'pre',
  'blockquote',
  'del',

  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'table',
  'thead',
  'tbody',
  'tfoot',

  'th',
  'tr',
  'td',
  // custom types (tagNames)
  //'emoji',
  //'mention',
];

export const matchMarkdownLinks = (message: string) => {
  const regexMdLinks = /\[([^[]+)\](\(.*\))/gm;
  const matches = message.match(regexMdLinks);
  const singleMatch = /\[([^[]+)\]\((.*)\)/;

  const links = matches
    ? matches.map((match) => {
        const i = singleMatch.exec(match);
        return i && [i[1], i[2]];
      })
    : [];

  return links.flat();
};

export const messageCodeBlocks = (message: string) => {
  const codeRegex = /```[a-z]*\n[\s\S]*?\n```|`[a-z]*[\s\S]*?`/gm;
  const matches = message.match(codeRegex);
  return matches || [];
};

//const detectHttp = /(http(s?):\/\/)?(www\.)?/;

/*function formatUrlForDisplay(url: string) {
  try {
    return decodeURIComponent(url).replace(detectHttp, '');
  } catch (e) {
    return url;
  }
}

function encodeDecode(url: string) {
  try {
    return encodeURI(decodeURIComponent(url));
  } catch (error) {
    return url;
  }
}*/

const Anchor = ({ children, href }: ComponentProps<'a'>) => {
  const isEmail = href?.startsWith('mailto:');
  const isUrl = href?.startsWith('http');

  if (!href || (!isEmail && !isUrl)) return <>{children}</>;

  return (
    <a
      className={clsx({ 'str-chat__message-url-link': isUrl })}
      href={href}
      rel='nofollow noreferrer noopener'
      target='_blank'
    >
      {children}
    </a>
  );
};

export const markDownRenderers = {
  a: Anchor,
}

export const renderText = (text: string) => {
  if (!text) return null;
  if (text.trim().length === 1) return <>{text}</>;
  let newText = text;

  const rehypeComponents = {
    ...markDownRenderers,
  };

  return (
    <ReactMarkdown
      allowedElements={allowedMarkups}
      components={rehypeComponents}
      rehypePlugins={[]}
      remarkPlugins={[[remarkGfm, { singleTilde: false }]]}
      skipHtml
      unwrapDisallowed
    >
      {newText}
    </ReactMarkdown>
  );
}

export type NodesData = {
  nodes: NodeData[];
};

export type ContentData = {
  lang: "en" | "ar";
  title: string;
  body: string;
};

export type NodeData = {
  id: number;
  content: ContentData[];
  weight: number;
  children?: NodeData[];
  parent_title?: number;
  parent_id?: number;
};

const getContent = (node: NodeData, language: string) => {
  const content = node.content.filter(c => language.startsWith(c.lang));
  if (content.length === 0) {
    return node.content[0];
  }
  return content[0];
};

export const getTitle = (node: NodeData, language: string) => {
  return getContent(node, language).title;
};

export const getBody = (node: NodeData, language: string) => {
  return renderText(getContent(node, language).body);
};

export const NodeView = () => {
  const [nodes, setNodes] = useState<NodeData[]>([] as NodeData[]);
  const [result, setResult] = useState<NodeData | null>(null);
  const [cards, setCards] = useState<NodeData[]>([]);
  const { i18n } = useTranslation();

  const { client } = useClient("1", "https://center.drmeshari.com");

  useEffect(() => {
    client
      .get<NodesData>("nodes")
      .then((res) => {
        setNodes(res.nodes);

        const cards: NodeData[] = [];
        for (let node of res.nodes) {
          if (!node?.parent_id) {
            cards.push(node);
          }
        }
        setCards(cards);
      })
      .catch((e) => console.log(e));
  }, [client]);

  const getParentRecursive = (cards: NodeData[], id: number) => {
    let current = nodes.find((el) => el?.id === id);
    if (current) {
      cards.unshift(current);
      if (current.parent_id) {
        getParentRecursive(cards, current.parent_id);
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const handleOutside = (place: number) => {
    setResult(null);
    let entrance = nodes.find((el) => el.id === place);
    if (entrance) {
      const cards: NodeData[] = [];
      cards.push(entrance);
      if (entrance.parent_id) {
        getParentRecursive(cards, entrance.parent_id);
      }

      if (cards[cards.length - 1].children === undefined) {
        setResult(cards[cards.length - 1]);
      } else {
        for (let item of cards[cards.length - 1].children!) {
          cards.push(item);
        }
      }
      setCards(cards);
    }
  };

  return (
    <div className="flex-1 w-full">
      {cards.map((item) => (
        <button
          key={item.id}
          type="button"
          onClick={() => handleOutside(item?.id)}
          className="sm:flex items-center w-full space-x-3 mt-2 px-4 h-12 bg-white ring-1 ring-slate-900/10 hover:ring-slate-300 focus:outline-none focus:ring-2 focus:ring-sky-500 shadow-sm rounded-lg text-slate-400 dark:bg-slate-800 dark:ring-0 dark:text-slate-300 dark:highlight-white/5 dark:hover:bg-slate-700"
        >
          <span
            className={`text-center ${
              item?.children !== undefined ? "font-bold" : ""
            }`}
          >
            {getTitle(item, i18n.language)}
          </span>
        </button>
      ))}

      {result && (
        <div className="whitespace-pre-wrap flex-auto mt-2 relative block text-slate-400 dark:bg-slate-800 overflow-auto rounded-lg shadow-sm p-4">
          {getBody(result, i18n.language)}
        </div>
      )}
    </div>
  );
};
