import { useEffect, useState } from "react";
import clsx from "clsx";
import {
  LangSelect,
  LangToggle,
  ThemeSelect,
  ThemeToggle,
} from "./ThemeToggle";
import { Link } from "react-router-dom";
import { Dialog } from "@headlessui/react";
import { Logo } from "./Logo";
import { useTranslation } from "react-i18next";

type NavPopoverProps = {
  display?: string;
  className?: string;
};

export function NavPopover({
  display = "md:hidden",
  className,
  ...props
}: NavPopoverProps) {
  let [isOpen, setIsOpen] = useState(false);

  /*useEffect(() => {
    if (!isOpen) return
    function handleRouteChange() {
      setIsOpen(false)
    }
    Router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [isOpen])*/

  return (
    <div className={clsx(className, display)} {...props}>
      <button
        type="button"
        className="text-slate-500 w-8 h-8 flex items-center justify-center hover:text-slate-600 dark:text-slate-400 dark:hover:text-slate-300"
        onClick={() => setIsOpen(true)}
      >
        <span className="sr-only">Navigation</span>
        <svg width="24" height="24" fill="none" aria-hidden="true">
          <path
            d="M12 6v.01M12 12v.01M12 18v.01M12 7a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm0 6a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm0 6a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      <Dialog
        as="div"
        className={clsx("fixed z-50 inset-0", display)}
        open={isOpen}
        onClose={setIsOpen}
      >
        <Dialog.Overlay className="fixed inset-0 bg-black/20 backdrop-blur-sm dark:bg-slate-900/80" />
        <div className="fixed top-4 right-4 w-full max-w-xs bg-white rounded-lg shadow-lg p-6 text-base font-semibold text-slate-900 dark:bg-slate-800 dark:text-slate-400 dark:highlight-white/5">
          <button
            type="button"
            className="absolute top-5 right-5 w-8 h-8 flex items-center justify-center text-slate-500 hover:text-slate-600 dark:text-slate-400 dark:hover:text-slate-300"
            onClick={() => setIsOpen(false)}
          >
            <span className="sr-only">Close navigation</span>
            <svg
              viewBox="0 0 10 10"
              className="w-2.5 h-2.5 overflow-visible"
              aria-hidden="true"
            >
              <path
                d="M0 0L10 10M10 0L0 10"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          </button>

          <div className="mt-6 pt-6 border-t border-slate-200 dark:border-slate-200/10">
            <ThemeSelect />
          </div>

          <div className="mt-6 pt-6 border-t border-slate-200 dark:border-slate-200/10">
            <LangSelect />
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export function NavItems() {
  const { t } = useTranslation();

  return (
    <>
      <li>
        <Link
          to="/about"
          className="hover:text-sky-500 dark:hover:text-sky-400"
        >
          {t("about")}
        </Link>
      </li>
    </>
  );
}

type HeaderProps = {
  hasNav?: boolean;
  navIsOpen?: boolean;
  onNavToggle?: (navIsOpen: boolean) => void;
  title?: string;
  section?: string;
};
export function Header({
  hasNav = false,
  navIsOpen = false,
  onNavToggle,
  title,
  section,
}: HeaderProps) {
  let [isOpaque, setIsOpaque] = useState(false);

  useEffect(() => {
    let offset = 50;
    function onScroll() {
      if (!isOpaque && window.scrollY > offset) {
        setIsOpaque(true);
      } else if (isOpaque && window.scrollY <= offset) {
        setIsOpaque(false);
      }
    }
    onScroll();
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", onScroll); // , { passive: true }
    };
  }, [isOpaque]);

  const handleNavToggle = (value: boolean) => {
    if (onNavToggle) onNavToggle(value);
  };

  return (
    <>
      <div className="absolute z-20 top-0 inset-x-0 flex justify-center overflow-hidden pointer-events-none">
        <div className="w-[108rem] flex-none flex justify-end">
          <picture>
            <source srcSet="./images/beams/docs@30.avif" type="image/avif" />
            <img
              src="./images/beams/docs@tinypng.png"
              alt=""
              className="w-[71.75rem] flex-none max-w-none dark:hidden"
              decoding="async"
            />
          </picture>
          <picture>
            <source
              srcSet="./images/beams/docs-dark@30.avif"
              type="image/avif"
            />
            <img
              src="./images/beams/docs-dark@tinypng.png"
              alt=""
              className="w-[90rem] flex-none max-w-none hidden dark:block"
              decoding="async"
            />
          </picture>
        </div>
      </div>
      <div
        className={clsx(
          "sticky top-0 z-40 w-full backdrop-blur flex-none transition-colors duration-500 lg:z-50 lg:border-b lg:border-slate-900/10 dark:border-slate-50/[0.06]",
          isOpaque
            ? "bg-white supports-backdrop-blur:bg-white/95 dark:bg-slate-900/75"
            : "bg-white/95 supports-backdrop-blur:bg-white/60 dark:bg-transparent"
        )}
      >
        <div className="max-w-8xl mx-auto">
          <div
            className={clsx(
              "py-4 border-b border-slate-900/10 lg:px-8 lg:border-0 dark:border-slate-300/10",
              hasNav ? "mx-4 lg:mx-0" : "px-4"
            )}
          >
            <div className="relative lg:flex lg:items-center">
              <Link
                to="/"
                className="mr-3 rtl:mr-0 rtl:ml-3 flex-none overflow-hidden"
                onContextMenu={(e) => {
                  e.preventDefault();
                  //Router.push('/brand')
                }}
              >
                <Logo className="w-auto h-12 flex items-center" />
              </Link>

              <div className="relative flex items-center ltr:ml-auto rtl:mr-auto">
                <div className="lg:hidden"></div>
                <div className="relative flex items-center ltr:ml-auto rtl:mr-auto">
                  <nav className="text-sm leading-6 font-semibold text-slate-700 dark:text-slate-200 ltr:mr-5 rtl:ml-5 ">
                    <ul className="flex space-x-8">
                      <NavItems />
                    </ul>
                  </nav>

                  <LangToggle panelClassName="mt-8" />
                  <div className="flex items-center ltr:border-l rtl:border-r border-slate-200 ltr:ml-6 ltr:pl-6 rtl:mr-6 rtl:pr-6 dark:border-slate-800">
                    <ThemeToggle panelClassName="mt-8" />
                  </div>
                </div>
              </div>

              {/*<NavPopover className="ltr:ml-2 rtl:mr-2 -my-1" display="lg:hidden" />*/}
            </div>
          </div>
          {hasNav && (
            <div className="flex items-center p-4 border-b border-slate-900/10 lg:hidden dark:border-slate-50/[0.06]">
              <button
                type="button"
                onClick={() => handleNavToggle(!navIsOpen)}
                className="text-slate-500 hover:text-slate-600 dark:text-slate-400 dark:hover:text-slate-300"
              >
                <span className="sr-only">Navigation</span>
                <svg width="24" height="24">
                  <path
                    d="M5 6h14M5 12h14M5 18h14"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </button>
              {title && (
                <ol className="ml-4 flex text-sm leading-6 whitespace-nowrap min-w-0">
                  {section && (
                    <li className="flex items-center">
                      {section}
                      <svg
                        width="3"
                        height="6"
                        aria-hidden="true"
                        className="mx-3 overflow-visible text-slate-400"
                      >
                        <path
                          d="M0 0L3 3L0 6"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                        />
                      </svg>
                    </li>
                  )}
                  <li className="font-semibold text-slate-900 truncate dark:text-slate-200">
                    {title}
                  </li>
                </ol>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

/*
<SearchButton className="ml-auto text-slate-500 w-8 h-8 -my-1 flex items-center justify-center hover:text-slate-600 lg:hidden dark:text-slate-400 dark:hover:text-slate-300">
                <span className="sr-only">Search</span>
                <svg
                  width="24"
                  height="24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  aria-hidden="true"
                >
                  <path d="m19 19-3.5-3.5" />
                  <circle cx="11" cy="11" r="6" />
                </svg>
              </SearchButton>
              */
