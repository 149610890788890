import { Route, Routes } from "react-router-dom";
import { Header } from "./components/template/Header";
import { NodeScreen } from "./components/screens/NodeScreen";
import { AboutScreen } from "./components/screens/AboutScreen";
// import { useClient } from "./hooks/useClient/useClient";
// import { NodesData } from "./components/NodeView";

function App() {
  //const { client } = useClient("1", "http://localhost:8080");
  return (
    <>
      <div className="mb-20 overflow-hidden sm:mb-32 md:mb-40">
        <Header />
        <Routes>
          <Route
            path="/"
            element={<NodeScreen />}
            // loader={async () => {
            //   const nodes = await client.get<NodesData>("nodes");
            //   return nodes;
            // }}
          >
            <Route path="/node/:nodeId" element={<NodeScreen />} />
          </Route>
          <Route path="/about" element={<AboutScreen />} />
        </Routes>
      </div>
    </>
  );
}

export default App;
