/* eslint no-unused-vars: "off" */
/* global process */

import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import https from 'https';

import { ClientState } from './client_state';
import { DevToken, JWTUserToken } from './signing';
import { TokenManager } from './token_manager';
import { isErrorResponse } from './errors';
import {
  addFileToFormData,
  chatCodes,
  isFunction,
  isOwnUserBaseProperty,
  normalizeQuerySort,
  randomId,
  retryInterval,
  sleep,
} from './utils';

import type {
  APIErrorResponse,
  APIResponse,
  AppSettings,
  AppSettingsAPIResponse,
  BlockList,
  BlockListResponse,
  CustomPermissionOptions,
  DeactivateUsersOptions,
  DefaultGenerics,
  DeleteUserOptions,
  Event,
  EventHandler,
  ExtendableGenerics,
  FlagsFilters,
  FlagsPaginationOptions,
  FlagsResponse,
  FlagUserResponse,
  GetCallTokenResponse,
  HistoriesResponse,
  HistoryFilters,
  HistoryPaginationOptions,
  HistorySort,
  Logger,
  LoginResponse,
  OGAttachment,
  OwnUserResponse,
  PartialUserUpdate,
  PermissionsAPIResponse,
  ReactivateUserOptions,
  ReactivateUsersOptions,
  RoleResponse,
  RolesAPIResponse,
  StreamChatOptions,
  TaskResponse,
  TokenOrProvider,
  User,
  UserCustomEvent,
  UserFilters,
  UserOptions,
  UserResponse,
  UserSort,
  UsersResponse,
  UserRegisterParams,
  TrainResponse,
  TrainDataAPIResponse,
  TrainQueryOptions,
  TrainFilters,
  TrainSort,
  MachineLearning,
  AiModerationResultResponse,
  ViolationFilters,
  ViolationSort,
  ViolationPaginationOptions,
  ViolationsResponse,
  ViolationOptions,
  ViolationResponse,
  ReviewFlagOptions,
} from './types';

import { ErrorFromResponse } from './types';
import { InsightMetrics, postInsights } from './insights';

export function isString(x: unknown): x is string {
  return typeof x === 'string' || x instanceof String;
}
export function isNumber(x: unknown): x is number {
  return typeof x === 'number' || x instanceof Number;
}

type AuthType = 'jwt' | 'token';

export class JClient<StreamChatGenerics extends ExtendableGenerics = DefaultGenerics> {
  private static _instance?: unknown | JClient; // type is undefined|JClient, unknown is due to TS limitations with statics

  _user?: OwnUserResponse<StreamChatGenerics> | UserResponse<StreamChatGenerics>;
  anonymous: boolean;
  authType: AuthType;
  persistUserOnConnectionFailure?: boolean;
  axiosInstance: AxiosInstance;
  baseURL?: string;
  browser: boolean;
  cleaningIntervalRef?: NodeJS.Timeout;
  clientID?: string;
  key: string;
  listeners: Record<string, Array<(event: Event<StreamChatGenerics>) => void>>;
  logger: Logger;
  /**
   * When network is recovered, we re-query the active channels on client. But in single query, you can recover
   * only 30 channels. So its not guaranteed that all the channels in activeChannels object have updated state.
   * Thus in UI sdks, state recovery is managed by components themselves, they don't rely on js client for this.
   *
   * `recoverStateOnReconnect` parameter can be used in such cases, to disable state recovery within js client.
   * When false, user/consumer of this client will need to make sure all the channels present on UI by
   * manually calling queryChannels endpoint.
   */
  recoverStateOnReconnect?: boolean;
  node: boolean;
  options: StreamChatOptions;
  secret?: string;
  state: ClientState;
  tokenManager: TokenManager<StreamChatGenerics>;
  user?: OwnUserResponse<StreamChatGenerics> | UserResponse<StreamChatGenerics>;
  userAgent?: string;
  userID?: number;
  consecutiveFailures: number;
  insightMetrics: InsightMetrics;
  defaultWSTimeoutWithFallback: number;
  defaultWSTimeout: number;
  private nextRequestAbortController: AbortController | null = null;

  /**
   * Initialize a client
   *
   * **Only use constructor for advanced usages. It is strongly advised to use `StreamChat.getInstance()` instead of `new StreamChat()` to reduce integration issues due to multiple WebSocket connections**
   * @param {string} key - the api key
   * @param {string} [secret] - the api secret
   * @param {StreamChatOptions} [options] - additional options, here you can pass custom options to axios instance
   * @param {boolean} [options.browser] - enforce the client to be in browser mode
   * @param {boolean} [options.warmUp] - default to false, if true, client will open a connection as soon as possible to speed up following requests
   * @param {Logger} [options.Logger] - custom logger
   * @param {number} [options.timeout] - default to 3000
   * @param {httpsAgent} [options.httpsAgent] - custom httpsAgent, in node it's default to https.agent()
   * @example <caption>initialize the client in user mode</caption>
   * new StreamChat('api_key')
   * @example <caption>initialize the client in user mode with options</caption>
   * new StreamChat('api_key', { warmUp:true, timeout:5000 })
   * @example <caption>secret is optional and only used in server side mode</caption>
   * new StreamChat('api_key', "secret", { httpsAgent: customAgent })
   */
  constructor(key: string, options?: StreamChatOptions);
  constructor(key: string, secret?: string, options?: StreamChatOptions);
  constructor(key: string, secretOrOptions?: StreamChatOptions | string, options?: StreamChatOptions) {
    // set the key
    this.key = key;
    this.listeners = {};
    this.state = new ClientState();

    // set the secret
    if (secretOrOptions && isString(secretOrOptions)) {
      this.secret = secretOrOptions;
      this.authType = 'jwt';
    } else {
      this.authType = 'token';
    }

    // set the options... and figure out defaults...
    const inputOptions = options ? options : secretOrOptions && !isString(secretOrOptions) ? secretOrOptions : {};

    this.browser = typeof inputOptions.browser !== 'undefined' ? inputOptions.browser : typeof window !== 'undefined';
    this.node = !this.browser;

    this.options = {
      timeout: 6000,
      withCredentials: false, // making sure cookies are not sent
      warmUp: false,
      recoverStateOnReconnect: true,
      ...inputOptions,
    };

    if (this.node && !this.options.httpsAgent) {
      this.options.httpsAgent = new https.Agent({
        keepAlive: true,
        keepAliveMsecs: 6000,
      });
    }

    this.axiosInstance = axios.create(this.options);

    this.setBaseURL(this.options.baseURL || 'https://chat.jkuwait.com/api');

    if (typeof process !== 'undefined' && process.env.STREAM_LOCAL_TEST_RUN) {
      this.setBaseURL('http://localhost:8080/api');
    }

    if (typeof process !== 'undefined' && process.env.STREAM_LOCAL_TEST_HOST) {
      this.setBaseURL('http://' + process.env.STREAM_LOCAL_TEST_HOST);
    }

    this.anonymous = false;
    this.persistUserOnConnectionFailure = this.options?.persistUserOnConnectionFailure;

    // If its a server-side client, then lets initialize the tokenManager, since token will be
    // generated from secret.
    this.tokenManager = new TokenManager(this.secret);
    this.consecutiveFailures = 0;
    this.insightMetrics = new InsightMetrics();

    this.defaultWSTimeoutWithFallback = 6000;
    this.defaultWSTimeout = 15000;

    /**
     * logger function should accept 3 parameters:
     * @param logLevel string
     * @param message   string
     * @param extraData object
     *
     * e.g.,
     * const client = new StreamChat('api_key', {}, {
     * 		logger = (logLevel, message, extraData) => {
     * 			console.log(message);
     * 		}
     * })
     *
     * extraData contains tags array attached to log message. Tags can have one/many of following values:
     * 1. api
     * 2. api_request
     * 3. api_response
     * 4. client
     * 5. channel
     * 6. connection
     * 7. event
     *
     * It may also contains some extra data, some examples have been mentioned below:
     * 1. {
     * 		tags: ['api', 'api_request', 'client'],
     * 		url: string,
     * 		payload: object,
     * 		config: object
     * }
     * 2. {
     * 		tags: ['api', 'api_response', 'client'],
     * 		url: string,
     * 		response: object
     * }
     * 3. {
     * 		tags: ['api', 'api_response', 'client'],
     * 		url: string,
     * 		error: object
     * }
     * 4. {
     * 		tags: ['event', 'client'],
     * 		event: object
     * }
     * 5. {
     * 		tags: ['channel'],
     * 		channel: object
     * }
     */
    this.logger = isFunction(inputOptions.logger) ? inputOptions.logger : () => null;
    this.recoverStateOnReconnect = this.options.recoverStateOnReconnect;
  }

  /**
   * Get a client instance
   *
   * This function always returns the same Client instance to avoid issues raised by multiple Client and WS connections
   *
   * **After the first call, the client configuration will not change if the key or options parameters change**
   *
   * @param {string} key - the api key
   * @param {string} [secret] - the api secret
   * @param {StreamChatOptions} [options] - additional options, here you can pass custom options to axios instance
   * @param {boolean} [options.browser] - enforce the client to be in browser mode
   * @param {boolean} [options.warmUp] - default to false, if true, client will open a connection as soon as possible to speed up following requests
   * @param {Logger} [options.Logger] - custom logger
   * @param {number} [options.timeout] - default to 3000
   * @param {httpsAgent} [options.httpsAgent] - custom httpsAgent, in node it's default to https.agent()
   * @example <caption>initialize the client in user mode</caption>
   * JClient.getInstance('api_key')
   * @example <caption>initialize the client in user mode with options</caption>
   * JClient.getInstance('api_key', { timeout:5000 })
   * @example <caption>secret is optional and only used in server side mode</caption>
   * JClient.getInstance('api_key', "secret", { httpsAgent: customAgent })
   */
  public static getInstance<StreamChatGenerics extends ExtendableGenerics = DefaultGenerics>(
    key: string,
    options?: StreamChatOptions,
  ): JClient<StreamChatGenerics>;
  public static getInstance<StreamChatGenerics extends ExtendableGenerics = DefaultGenerics>(
    key: string,
    secret?: string,
    options?: StreamChatOptions,
  ): JClient<StreamChatGenerics>;
  public static getInstance<StreamChatGenerics extends ExtendableGenerics = DefaultGenerics>(
    key: string,
    secretOrOptions?: StreamChatOptions | string,
    options?: StreamChatOptions,
  ): JClient<StreamChatGenerics> {
    if (!JClient._instance) {
      if (typeof secretOrOptions === 'string') {
        JClient._instance = new JClient<StreamChatGenerics>(key, secretOrOptions, options);
      } else {
        JClient._instance = new JClient<StreamChatGenerics>(key, secretOrOptions);
      }
    }

    return JClient._instance as JClient<StreamChatGenerics>;
  }

  devToken(userID: string) {
    return DevToken(userID);
  }

  getAuthType() {
    return this.authType;
  }

  setBaseURL(baseURL: string) {
    this.baseURL = baseURL;
  }

  async me(token?: string) {
    const event = await this.get<Event<StreamChatGenerics>>(this.baseURL + '/auth/me', null, token);
    if (event.me && token) {
      await this.setUser(event.me, token);
    }
    return event;
  }

  async login(login: string, password: string) {
    const response = await this.post<LoginResponse<StreamChatGenerics>>(this.baseURL + '/auth/login', {
      login,
      password,
    });
    await this.setUser(response.user, response.token);
    return response;
  }

  async guest(name: string) {
    const response = await this.post<LoginResponse<StreamChatGenerics>>(this.baseURL + '/auth/register/guest', {
      name,
    });
    await this.setUser(response.user, response.token);
    return response;
  }

  async register(params: UserRegisterParams) {
    const response = await this.post<LoginResponse<StreamChatGenerics>>(this.baseURL + '/auth/register', params);
    await this.setUser(response.user, response.token);
    return response;
  }

  async forgot(login: string) {
    return await this.post<void>(this.baseURL + '/auth/password/forgot', { login });
  }

  //Check
  async forgotVerifyWithToken(token: string) {
    return await this.get<void>(this.baseURL + '/auth/password/forgot', { token });
  }

  async password(password: string, token: string) {
    return await this.put<void>(this.baseURL + '/auth/password/forgot', { password }, { 'Access-Token': token });
  }

  /**
   *
   * setUser - Set the current user and open a WebSocket connection
   *
   * @param {OwnUserResponse<StreamChatGenerics> | UserResponse<StreamChatGenerics>} user Data about this user. IE {name: "john"}
   * @param {TokenOrProvider} userTokenOrProvider Token or provider
   *
   * @return {ConnectAPIResponse<StreamChatGenerics>} Returns a promise that resolves when the connection is setup
   */
  setUser = (
    user: OwnUserResponse<StreamChatGenerics> | UserResponse<StreamChatGenerics>,
    userTokenOrProvider: TokenOrProvider,
  ) => {
    this.anonymous = false;
    this._setUser(user);
    return this._setToken(user, userTokenOrProvider);
  };

  _setToken = (user: UserResponse<StreamChatGenerics>, userTokenOrProvider: TokenOrProvider) =>
    this.tokenManager.setTokenOrProvider(userTokenOrProvider, user);

  _setUser(user: OwnUserResponse<StreamChatGenerics> | UserResponse<StreamChatGenerics>) {
    if (!user?.id) {
      throw new Error('The "id" field on the user is missing');
    }

    /**
     * This one is used by the frontend. This is a copy of the current user object stored on backend.
     * It contains reserved properties and own user properties which are not present in `this._user`.
     */
    this.user = user;
    this.userID = user.id;
    // this one is actually used for requests. This is a copy of current user provided to `connectUser` function.
    this._user = { ...user };
  }

  /**
   * updateAppSettings - updates application settings
   *
   * @param {AppSettings} options App settings.
   * IE: {
      'apn_config': {
        'auth_type': 'token',
        'auth_key": fs.readFileSync(
          './apn-push-auth-key.p8',
          'utf-8',
        ),
        'key_id': 'keyid',
        'team_id': 'teamid',
        'notification_template": 'notification handlebars template',
        'bundle_id': 'com.apple.your.app',
        'development': true
      },
      'firebase_config': {
        'server_key': 'server key from fcm',
        'notification_template': 'notification handlebars template',
        'data_template': 'data handlebars template',
        'apn_template': 'apn notification handlebars template under v2'
      },
      'webhook_url': 'https://acme.com/my/awesome/webhook/'
    }
   */
  async updateAppSettings(options: AppSettings) {
    const apn_config = options.apn_config;
    if (apn_config?.p12_cert) {
      options = {
        ...options,
        apn_config: {
          ...apn_config,
          p12_cert: Buffer.from(apn_config.p12_cert).toString('base64'),
        },
      };
    }
    return await this.patch<APIResponse>(this.baseURL + '/app', options);
  }

  _normalizeDate = (before: Date | string | null): string | null => {
    if (before instanceof Date) {
      before = before.toISOString();
    }

    if (before === '') {
      throw new Error("Don't pass blank string for since, use null instead if resetting the token revoke");
    }

    return before;
  };

  /**
   * Revokes all tokens on application level issued before given time
   */
  async revokeTokens(before: Date | string | null) {
    return await this.updateAppSettings({
      revoke_tokens_issued_before: this._normalizeDate(before),
    });
  }

  /**
   * getAppSettings - retrieves application settings
   */
  async getAppSettings() {
    return await this.get<AppSettingsAPIResponse<StreamChatGenerics>>(this.baseURL + '/app');
  }

  /**
   * createToken - Creates a token to authenticate this user. This function is used server side.
   * The resulting token should be passed to the client side when the users registers or logs in.
   *
   * @param {number} userID The User ID
   * @param {number} [exp] The expiration time for the token expressed in the number of seconds since the epoch
   *
   * @return {string} Returns a token
   */
  createToken(userID: number, exp?: number, iat?: number) {
    if (this.secret == null) {
      throw Error(`tokens can only be created server-side using the API Secret`);
    }
    const extra: { exp?: number; iat?: number } = {};

    if (exp) {
      extra.exp = exp;
    }

    if (iat) {
      extra.iat = iat;
    }

    return JWTUserToken(this.secret, userID, extra, {});
  }

  /**
   * on - Listen to events on all channels and users your watching
   *
   * client.on('message.new', event => {console.log("my new message", event, channel.state.messages)})
   * or
   * client.on(event => {console.log(event.type)})
   *
   * @param {EventHandler<StreamChatGenerics> | string} callbackOrString  The event type to listen for (optional)
   * @param {EventHandler<StreamChatGenerics>} [callbackOrNothing] The callback to call
   *
   * @return {{ unsubscribe: () => void }} Description
   */
  on(callback: EventHandler<StreamChatGenerics>): { unsubscribe: () => void };
  on(eventType: string, callback: EventHandler<StreamChatGenerics>): { unsubscribe: () => void };
  on(
    callbackOrString: EventHandler<StreamChatGenerics> | string,
    callbackOrNothing?: EventHandler<StreamChatGenerics>,
  ): { unsubscribe: () => void } {
    const key = callbackOrNothing ? (callbackOrString as string) : 'all';
    const callback = callbackOrNothing ? callbackOrNothing : (callbackOrString as EventHandler<StreamChatGenerics>);
    if (!(key in this.listeners)) {
      this.listeners[key] = [];
    }
    this.logger('info', `Attaching listener for ${key} event`, {
      tags: ['event', 'client'],
    });
    this.listeners[key].push(callback);
    return {
      unsubscribe: () => {
        this.logger('info', `Removing listener for ${key} event`, {
          tags: ['event', 'client'],
        });
        this.listeners[key] = this.listeners[key].filter((el) => el !== callback);
      },
    };
  }

  /**
   * off - Remove the event handler
   *
   */
  off(callback: EventHandler<StreamChatGenerics>): void;
  off(eventType: string, callback: EventHandler<StreamChatGenerics>): void;
  off(
    callbackOrString: EventHandler<StreamChatGenerics> | string,
    callbackOrNothing?: EventHandler<StreamChatGenerics>,
  ) {
    const key = callbackOrNothing ? (callbackOrString as string) : 'all';
    const callback = callbackOrNothing ? callbackOrNothing : (callbackOrString as EventHandler<StreamChatGenerics>);
    if (!(key in this.listeners)) {
      this.listeners[key] = [];
    }

    this.logger('info', `Removing listener for ${key} event`, {
      tags: ['event', 'client'],
    });
    this.listeners[key] = this.listeners[key].filter((value) => value !== callback);
  }

  _logApiRequest(
    type: string,
    url: string,
    data: unknown,
    config: AxiosRequestConfig & {
      config?: AxiosRequestConfig & { maxBodyLength?: number };
    },
  ) {
    this.logger('info', `client: ${type} - Request - ${url}`, {
      tags: ['api', 'api_request', 'client'],
      url,
      payload: data,
      config,
    });
  }

  _logApiResponse<T>(type: string, url: string, response: AxiosResponse<T>) {
    this.logger('info', `client:${type} - Response - url: ${url} > status ${response.status}`, {
      tags: ['api', 'api_response', 'client'],
      url,
      response,
    });
  }

  _logApiError(type: string, url: string, error: unknown) {
    this.logger('error', `client:${type} - Error - url: ${url}`, {
      tags: ['api', 'api_response', 'client'],
      url,
      error,
    });
  }

  _url(url: string) {
    if (!url.startsWith('http')) {
      return this.baseURL + (!url.startsWith('/') ? '/' : '') + url;
    }
    return url;
  }

  doAxiosRequest = async <T>(
    type: string,
    url: string,
    data?: unknown,
    options: AxiosRequestConfig & {
      config?: AxiosRequestConfig & { maxBodyLength?: number };
      token?: string;
    } = {},
  ): Promise<T> => {
    await this.tokenManager.tokenReady();
    const requestConfig = this._enrichAxiosOptions(options);
    try {
      let response: AxiosResponse<T>;
      this._logApiRequest(type, url, data, requestConfig);
      switch (type) {
        case 'get':
          response = await this.axiosInstance.get(this._url(url), requestConfig);
          break;
        case 'delete':
          response = await this.axiosInstance.delete(this._url(url), requestConfig);
          break;
        case 'post':
          response = await this.axiosInstance.post(this._url(url), data, requestConfig);
          break;
        case 'put':
          response = await this.axiosInstance.put(this._url(url), data, requestConfig);
          break;
        case 'patch':
          response = await this.axiosInstance.patch(this._url(url), data, requestConfig);
          break;
        case 'options':
          response = await this.axiosInstance.options(this._url(url), requestConfig);
          break;
        default:
          throw new Error('Invalid request type');
      }
      this._logApiResponse<T>(type, url, response);
      this.consecutiveFailures = 0;
      return this.handleResponse(response);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any /**TODO: generalize error types  */) {
      e.client_request_id = requestConfig.headers?.['x-client-request-id'];
      this._logApiError(type, url, e);
      this.consecutiveFailures += 1;
      if (e.response) {
        /** connection_fallback depends on this token expiration logic */
        if (e.response.data.code === chatCodes.TOKEN_EXPIRED && !this.tokenManager.isStatic()) {
          if (this.consecutiveFailures > 1) {
            await sleep(retryInterval(this.consecutiveFailures));
          }
          this.tokenManager.loadToken();
          return await this.doAxiosRequest<T>(type, url, data, options);
        }
        return this.handleResponse(e.response);
      } else {
        throw e as AxiosError<APIErrorResponse>;
      }
    }
  };

  get<T>(url: string, params?: AxiosRequestConfig['params'], token?: string) {
    return this.doAxiosRequest<T>('get', url, null, { params, token });
  }

  put<T>(url: string, data?: unknown, headers?: AxiosRequestConfig['headers']) {
    return this.doAxiosRequest<T>('put', url, data, { headers });
  }

  post<T>(url: string, data?: unknown) {
    return this.doAxiosRequest<T>('post', url, data);
  }

  patch<T>(url: string, data?: unknown) {
    return this.doAxiosRequest<T>('patch', url, data);
  }

  delete<T>(url: string, params?: AxiosRequestConfig['params']) {
    return this.doAxiosRequest<T>('delete', url, null, { params });
  }

  sendFile<T>(
    url: string,
    uri: string | NodeJS.ReadableStream | Buffer | File,
    name?: string,
    contentType?: string,
    user?: UserResponse<StreamChatGenerics>,
  ) {
    const data = addFileToFormData(uri, name, contentType);
    if (user != null) data.append('user_id', user.id);
    return this.doAxiosRequest<T>('post', url, data, {
      headers: data.getHeaders ? data.getHeaders() : {}, // node vs browser
      config: {
        timeout: 0,
        maxContentLength: Infinity,
        maxBodyLength: Infinity,
      },
    });
  }

  errorFromResponse(response: AxiosResponse<APIErrorResponse>): ErrorFromResponse<APIErrorResponse> {
    let err: ErrorFromResponse<APIErrorResponse>;
    err = new ErrorFromResponse(`StreamChat error HTTP code: ${response.status}`);
    if (response.data && response.data.code) {
      err = new Error(`StreamChat error code ${response.data.code}: ${response.data.message}`);
      err.code = response.data.code;
    }

    if (response.data && response.data.error) {
      if (!isString(response.data.error)) {
        err = new Error(`StreamChat error code ${response.data.error.code}: ${response.data.error.message}`);
        err.code = response.data.error.code;
      } else {
        err = new Error(`StreamChat error code ${response.data.status}: ${response.data.error}`);
        err.code = response.data.status;
      }
    }

    if (response.data && response.data.validation_errors) {
      err.validation_errors = response.data.validation_errors;
    }
    err.response = response;
    err.status = response.status;
    return err;
  }

  handleResponse<T>(response: AxiosResponse<T>) {
    const data = response.data;
    if (isErrorResponse(response)) {
      throw this.errorFromResponse(response);
    }
    return data;
  }

  dispatchEvent = (event: Event<StreamChatGenerics>) => {
    if (!event.received_at) event.received_at = new Date();

    // client event handlers
    this._handleClientEvent(event);
    this._callClientListeners(event);
  };

  /**
   * @private
   *
   * Handle following user related events:
   * - user.presence.changed
   * - user.updated
   * - user.deleted
   *
   * @param {Event} event
   */
  _handleUserEvent = (event: Event<StreamChatGenerics>) => {
    if (!event.user) {
      return;
    }

    /** update the client.state with any changes to users */
    this._updateStateUser(event.type, event.user);
  };

  _updateStateUser(type: string, user: OwnUserResponse<StreamChatGenerics> | UserResponse<StreamChatGenerics>) {
    if (type === 'user.presence.changed' || type === 'user.updated') {
      if (user.id === this.userID) {
        this.updateStateUser(user);
      }

      this.state.updateUser(user);
    }
  }

  updateStateUser(newData: OwnUserResponse<StreamChatGenerics> | UserResponse<StreamChatGenerics>) {
    const user = { ...(this.user || {}) };
    const _user = { ...(this._user || {}) };

    // Remove deleted properties from user objects.
    for (const key in this.user) {
      if (key in newData || isOwnUserBaseProperty(key)) {
        continue;
      }

      delete user[key];
      delete _user[key];
    }

    /** Updating only available properties in _user object. */
    for (const key in newData) {
      if (_user && key in _user) {
        _user[key] = newData[key];
      }
    }

    // @ts-expect-error
    this._user = { ..._user };
    this.user = { ...user, ...newData };
  }

  _handleClientEvent(event: Event<StreamChatGenerics>) {
    const client = this;
    this.logger('info', `client:_handleClientEvent - Received event of type { ${event.type} }`, {
      tags: ['event', 'client'],
      event,
    });

    if (event.type === 'user.presence.changed' || event.type === 'user.updated' || event.type === 'user.deleted') {
      this._handleUserEvent(event);
    }

    if (event.type === 'health.check' && event.me) {
      client.user = event.me;
      client.state.updateUser(event.me);
    }
  }

  _callClientListeners = (event: Event<StreamChatGenerics>) => {
    const client = this;
    // gather and call the listeners
    const listeners: Array<(event: Event<StreamChatGenerics>) => void> = [];
    if (client.listeners.all) {
      listeners.push(...client.listeners.all);
    }
    if (client.listeners[event.type]) {
      listeners.push(...client.listeners[event.type]);
    }

    // call the event and send it to the listeners
    for (const listener of listeners) {
      listener(event);
    }
  };

  /**
   * Check the connectivity with server for warmup purpose.
   *
   * @private
   */
  _sayHi() {
    const client_request_id = randomId();
    const opts = { headers: { 'x-client-request-id': client_request_id } };
    this.doAxiosRequest('get', this.baseURL + '/hi', null, opts).catch((e) => {
      if (this.options.enableInsights) {
        postInsights('http_hi_failed', {
          api_key: this.key,
          err: e,
          client_request_id,
        });
      }
    });
  }

  /**
   * queryUsers - Query users and watch user presence
   *
   * @param {UserFilters<StreamChatGenerics>} filterConditions MongoDB style filter conditions
   * @param {UserSort<StreamChatGenerics>} sort Sort options, for instance [{last_active: -1}].
   * When using multiple fields, make sure you use array of objects to guarantee field order, for instance [{last_active: -1}, {created_at: 1}]
   * @param {UserOptions} options Option object, {presence: true}
   *
   * @return {Promise<{ users: Array<UserResponse<StreamChatGenerics>> }>} User Query Response
   */
  async queryUsers(
    filterConditions: UserFilters<StreamChatGenerics>,
    sort: UserSort<StreamChatGenerics> = [],
    options: UserOptions = {},
  ) {
    const defaultOptions = {
      presence: false,
    };

    // Return a list of users
    const data = await this.post<UsersResponse<StreamChatGenerics>>(this.baseURL + '/users', {
      filter_conditions: filterConditions,
      sort: normalizeQuerySort(sort),
      ...defaultOptions,
      ...options,
    });
    this.state.updateUsers(data.users);

    return data;
  }

  /**
   * queryViolations - Query user violations
   *
   * @param {ViolationFilters} filterConditions MongoDB style filter conditions
   * @param {ViolationSort} sort Sort options [{created_at: 1}].
   * @param {ViolationPaginationOptions} options Option object, {limit: 10, offset:0}
   *
   * @return {Promise<ViolationsResponse<StreamChatGenerics>>} Violation Query Response
   */
  async queryViolations(
    filterConditions: ViolationFilters = {},
    sort: ViolationSort = [],
    options: ViolationPaginationOptions = {},
  ) {
    return await this.post<ViolationsResponse<StreamChatGenerics>>(this.baseURL + '/moderation/violations', {
      filter_conditions: filterConditions,
      sort: normalizeQuerySort(sort),
      ...options,
    });
  }

  /**
   * queryHistory - Query history
   *
   * @param {HistoryFilters} filterConditions MongoDB style filter conditions
   * @param {HistorySort} sort Sort options [{created_at: 1}].
   * @param {HistoryPaginationOptions} options Option object, {limit: 10, offset:0}
   *
   * @return {Promise<HistoryResponse<StreamChatGenerics>>} Ban Query Response
   */
  async queryHistory(
    filterConditions: HistoryFilters = {},
    sort: HistorySort = [],
    options: HistoryPaginationOptions = {},
  ) {
    // Return a list of histories
    return await this.post<HistoriesResponse<StreamChatGenerics>>(this.baseURL + '/moderation/history', {
      filter_conditions: filterConditions,
      sort: normalizeQuerySort(sort),
      ...options,
    });
  }

  /**
   * partialUpdateUser - Update the given user object
   *
   * @param {PartialUserUpdate<StreamChatGenerics>} partialUserObject which should contain id and any of "set" or "unset" params;
   * example: {id: "user1", set:{field: value}, unset:["field2"]}
   *
   * @return {Promise<{ users: { [key: string]: UserResponse<StreamChatGenerics> } }>} list of updated users
   */
  async partialUpdateUser(partialUserObject: PartialUserUpdate<StreamChatGenerics>) {
    return await this.partialUpdateUsers([partialUserObject]);
  }

  /**
   * upsertUsers - Batch upsert the list of users
   *
   * @param {UserResponse<StreamChatGenerics>[]} users list of users
   *
   * @return {Promise<{ users: { [key: string]: UserResponse<StreamChatGenerics> } }>}
   */
  async upsertUsers(users: UserResponse<StreamChatGenerics>[]) {
    const userMap: { [key: string]: UserResponse<StreamChatGenerics> } = {};
    for (const userObject of users) {
      if (!userObject.id) {
        throw Error('User ID is required when updating a user');
      }
      userMap[userObject.id] = userObject;
    }

    return await this.post<
      APIResponse & {
        users: { [key: string]: UserResponse<StreamChatGenerics> };
      }
    >(this.baseURL + '/users', { users: userMap });
  }

  /**
   * @deprecated Please use upsertUsers() function instead.
   *
   * updateUsers - Batch update the list of users
   *
   * @param {UserResponse<StreamChatGenerics>[]} users list of users
   * @return {Promise<{ users: { [key: string]: UserResponse<StreamChatGenerics> } }>}
   */
  updateUsers = this.upsertUsers;

  /**
   * upsertUser - Update or Create the given user object
   *
   * @param {UserResponse<StreamChatGenerics>} userObject user object, the only required field is the user id. IE {id: "myuser"} is valid
   *
   * @return {Promise<{ users: { [key: string]: UserResponse<StreamChatGenerics> } }>}
   */
  upsertUser(userObject: UserResponse<StreamChatGenerics>) {
    return this.upsertUsers([userObject]);
  }

  /**
   *
   * updateUser - Update or Create the given user object
   *
   * @param {UserResponse<StreamChatGenerics>} userObject user object, the only required field is the user id. IE {id: "myuser"} is valid
   * @return {Promise<UserResponse<StreamChatGenerics>>}
   */
  async updateUser(data: { [key: string]: any }): Promise<UserResponse<StreamChatGenerics>> {
    const user = await this.post<APIResponse & UserResponse<StreamChatGenerics>>(this.baseURL + '/users/update', data);
    this._updateStateUser('user.updated', user);

    return user;
  }

  async pictureUser(
    uri: string | NodeJS.ReadableStream | Buffer | File,
    userId?: number,
  ): Promise<OwnUserResponse<StreamChatGenerics>> {
    const user = await this.sendFile<APIResponse & OwnUserResponse<StreamChatGenerics>>(
      this.baseURL + '/users/picture',
      uri,
      undefined,
      undefined,
      userId !== undefined ? ({ id: userId } as UserResponse<StreamChatGenerics>) : undefined,
    );
    this._updateStateUser('user.updated', user);
    return user;
  }

  async bannerUser(
    uri: string | NodeJS.ReadableStream | Buffer | File,
    userId?: number,
  ): Promise<OwnUserResponse<StreamChatGenerics>> {
    const user = await this.sendFile<APIResponse & OwnUserResponse<StreamChatGenerics>>(
      this.baseURL + '/users/banner',
      uri,
      undefined,
      undefined,
      userId !== undefined ? ({ id: userId } as UserResponse<StreamChatGenerics>) : undefined,
    );
    this._updateStateUser('user.updated', user);
    return user;
  }

  /**
   * partialUpdateUsers - Batch partial update of users
   *
   * @param {PartialUserUpdate<StreamChatGenerics>[]} users list of partial update requests
   *
   * @return {Promise<{ users: { [key: string]: UserResponse<StreamChatGenerics> } }>}
   */
  async partialUpdateUsers(users: PartialUserUpdate<StreamChatGenerics>[]) {
    for (const userObject of users) {
      if (!userObject.id) {
        throw Error('User ID is required when updating a user');
      }
    }

    return await this.patch<
      APIResponse & {
        users: { [key: string]: UserResponse<StreamChatGenerics> };
      }
    >(this.baseURL + '/users', { users });
  }

  async deleteUser(
    userID: string,
    params?: {
      delete_conversation_channels?: boolean;
      hard_delete?: boolean;
      mark_messages_deleted?: boolean;
    },
  ) {
    return await this.delete<
      APIResponse & { user: UserResponse<StreamChatGenerics> } & {
        task_id?: string;
      }
    >(this.baseURL + `/users/${userID}`, params);
  }

  /**
   * restoreUsers - Restore soft deleted users
   *
   * @param {string[]} user_ids which users to restore
   *
   * @return {APIResponse} An API response
   */
  async restoreUsers(user_ids: string[]) {
    return await this.post<APIResponse>(this.baseURL + `/users/restore`, {
      user_ids,
    });
  }

  /**
   * reactivateUser - Reactivate one user
   *
   * @param {string} userID which user to reactivate
   * @param {ReactivateUserOptions} [options]
   *
   * @return {UserResponse} Reactivated user
   */
  async reactivateUser(userID: string, options?: ReactivateUserOptions) {
    return await this.post<APIResponse & { user: UserResponse<StreamChatGenerics> }>(
      this.baseURL + `/users/${userID}/reactivate`,
      { ...options },
    );
  }

  /**
   * reactivateUsers - Reactivate many users asynchronously
   *
   * @param {string[]} user_ids which users to reactivate
   * @param {ReactivateUsersOptions} [options]
   *
   * @return {TaskResponse} A task ID
   */
  async reactivateUsers(user_ids: string[], options?: ReactivateUsersOptions) {
    return await this.post<APIResponse & TaskResponse>(this.baseURL + `/users/reactivate`, { user_ids, ...options });
  }

  /**
   * deactivateUser - Deactivate one user
   *
   * @param {string} userID which user to deactivate
   * @param {DeactivateUsersOptions} [options]
   *
   * @return {UserResponse} Deactivated user
   */
  async deactivateUser(userID: string, options?: DeactivateUsersOptions) {
    return await this.post<APIResponse & { user: UserResponse<StreamChatGenerics> }>(
      this.baseURL + `/users/${userID}/deactivate`,
      { ...options },
    );
  }

  /**
   * deactivateUsers - Deactivate many users asynchronously
   *
   * @param {string[]} user_ids which users to deactivate
   * @param {DeactivateUsersOptions} [options]
   *
   * @return {TaskResponse} A task ID
   */
  async deactivateUsers(user_ids: string[], options?: DeactivateUsersOptions) {
    return await this.post<APIResponse & TaskResponse>(this.baseURL + `/users/deactivate`, { user_ids, ...options });
  }

  /** violation - violation a user
   *
   * @param {number} targetUserID
   * @param {ViolationOptions} [options]
   * @returns {Promise<APIResponse>}
   */
  async violation(targetUserID: number, options?: ViolationOptions): Promise<ViolationResponse<StreamChatGenerics> & APIResponse>;
  async violation(targetUserID: string, options?: ViolationOptions): Promise<ViolationResponse<StreamChatGenerics> & APIResponse>;
  async violation(targetUserID: number | string, options?: ViolationOptions) {
    return await this.post<ViolationResponse<StreamChatGenerics> & APIResponse>(this.baseURL + '/moderation/violation', {
      target_id: (isNumber(targetUserID) ? targetUserID : undefined),
      target_username: (isString(targetUserID) ? targetUserID : undefined),
      ...options,
    });
  }

  /** removeViolation - remove violation
   *
   * @param {number} targetUserID
   * @param {ViolationOptions} [options]
   * @returns {Promise<APIResponse>}
   */
  async removeViolation(targetUserID: number, options?: ViolationOptions) {
    return await this.post<APIResponse>(this.baseURL + '/moderation/remove-violation', {
      target_id: targetUserID,
      ...options,
    });
  }

  /**
   * flagUser - flag a user
   * @param {string} targetID
   * @param {number} [options.user_id] currentUserID, only used with serverside auth
   * @returns {Promise<APIResponse>}
   */
  async flagUser(targetID: string, options: { user_id?: number } = {}) {
    return await this.post<FlagUserResponse<StreamChatGenerics>>(this.baseURL + '/moderation/flag', {
      target_user_id: targetID,
      ...options,
    });
  }

  /**
   * unflagUser - unflag a user
   * @param {string} targetID
   * @param {number} [options.user_id] currentUserID, only used with serverside auth
   * @returns {Promise<APIResponse>}
   */
  async unflagUser(targetID: string, options: { user_id?: number } = {}) {
    return await this.post<FlagUserResponse<StreamChatGenerics>>(this.baseURL + '/moderation/unflag', {
      target_user_id: targetID,
      ...options,
    });
  }

  /**
   * getCallToken - retrieves the auth token needed to join a call
   *
   * @param {string} callID
   * @param {object} options
   * @returns {Promise<GetCallTokenResponse>}
   */
  async getCallToken(callID: string, options: { user_id?: string } = {}) {
    return await this.post<GetCallTokenResponse>(this.baseURL + `/calls/${callID}`, { ...options });
  }

  /**
   * queryFlags - Query flags.
   *
   * @private
   * @param {FlagsFilters} filterConditions MongoDB style filter conditions
   * @param {FlagsPaginationOptions} options Option object, {limit: 10, offset:0}
   *
   * @return {Promise<FlagsResponse<StreamChatGenerics>>} Flags Response
   */
  async queryFlags(filterConditions: FlagsFilters = {}, options: FlagsPaginationOptions = {}) {
    // Return a list of flags
    return await this.post<FlagsResponse<StreamChatGenerics>>(this.baseURL + '/moderation/flags', {
      filter_conditions: filterConditions,
      ...options,
    });
  }

  /**
   * _reviewFlag - review flag report
   *
   * @param {ReviewFlagOptions} options Option object
   * 
   * @returns {Promise<FlagsResponse<StreamChatGenerics>>>}
   */
  async reviewFlag(options: ReviewFlagOptions) {
    return await this.post<FlagsResponse<StreamChatGenerics>>(this.baseURL + '/moderation/flag/review', {
      ...options,
    });
  }

  getUserAgent() {
    return (
      this.userAgent || `stream-chat-javascript-client-${this.node ? 'node' : 'browser'}-${process.env.PKG_VERSION}`
    );
  }

  setUserAgent(userAgent: string) {
    this.userAgent = userAgent;
  }

  /**
   * _isUsingServerAuth - Returns true if we're using server side auth
   */
  _isUsingServerAuth = () => !!this.secret;

  _enrichAxiosOptions(
    options: AxiosRequestConfig & { config?: AxiosRequestConfig; token?: string } = {
      params: {},
      headers: {},
      config: {},
    },
  ): AxiosRequestConfig {
    const token = options.token ? options.token : this._getToken();
    const authorization = token ? { Authorization: token } : undefined;
    let signal: AbortSignal | null = null;
    if (this.nextRequestAbortController !== null) {
      signal = this.nextRequestAbortController.signal;
      this.nextRequestAbortController = null;
    }

    if (!options.headers?.['x-client-request-id']) {
      options.headers = {
        ...options.headers,
        'x-client-request-id': randomId(),
      };
    }

    const { params: axiosRequestConfigParams, headers: axiosRequestConfigHeaders, ...axiosRequestConfigRest } =
      this.options.axiosRequestConfig || {};

    return {
      params: {
        //user_id: this.userID,
        api_key: this.key,
        ...options.params,
      },
      headers: {
        ...authorization,
        'stream-auth-type': this.getAuthType(),
        'X-Stream-Client': this.getUserAgent(),
        ...options.headers,
        ...(axiosRequestConfigHeaders || {}),
      },
      ...(signal ? { signal } : {}),
      ...options.config,
      ...(axiosRequestConfigRest || {}),
    };
  }

  _getToken() {
    if (!this.tokenManager || this.anonymous) return null;

    return this.tokenManager.getToken();
  }

  /** updatePermission - updates an existing custom permission
   *
   * @param {string} id
   * @param {Omit<CustomPermissionOptions, 'id'>} permissionData the permission data
   * @returns {Promise<APIResponse>}
   */
  updatePermission(id: string, permissionData: Omit<CustomPermissionOptions, 'id'>) {
    return this.put<APIResponse>(`${this.baseURL}/permissions/${id}`, {
      ...permissionData,
    });
  }

  /** listPermissions - returns the list of all permissions for this role
   *
   * @param {string} id
   * @returns {Promise<APIResponse>}
   */
  listPermissions(id: string) {
    return this.get<PermissionsAPIResponse>(`${this.baseURL}/${id}/permissions`);
  }

  /** createRole - creates a custom role
   *
   * @param {string} name the new role name
   * @returns {Promise<APIResponse>}
   */
  createRole(name: string) {
    return this.post<APIResponse & RoleResponse>(`${this.baseURL}/roles`, { name });
  }

  /** updateRole - update a custom role
   *
   * @param {RoleResponse} role
   * @returns {Promise<APIResponse>}
   */
  updateRole(role: RoleResponse): Promise<APIResponse & RoleResponse> {
    return this.post<APIResponse & RoleResponse>(`${this.baseURL}/roles`, { ...role });
  }

  /** listRoles - returns the list of all roles for this application
   *
   * @returns {Promise<RolesAPIResponse>}
   */
  listRoles(): Promise<RolesAPIResponse> {
    return this.get<RolesAPIResponse>(`${this.baseURL}/roles/list`);
  }

  /** deleteRole - deletes a custom role
   *
   * @param {string} name the role name
   * @returns {Promise<APIResponse>}
   */
  deleteRole(name: string) {
    return this.delete<APIResponse>(`${this.baseURL}/roles/${name}`);
  }

  /** userRoleToggle - add or remove role
   *
   * @param {number} user_id the user id
   * @param {string} id the role id
   * @returns {Promise<APIResponse>}
   */
  userRoleToggle(user_id: number, machine: string): Promise<APIResponse & UserResponse<StreamChatGenerics>> {
    return this.post<APIResponse & UserResponse<StreamChatGenerics>>(`${this.baseURL}/users/role`, {
      user_id,
      machine,
    });
  }

  hasRole(machine: string, user?: User<StreamChatGenerics>) {
    if (!user) {
      user = this.user;
    }
    if (!user) return false;
    if (!user?.roles) return false;
    if (user?.role === machine) return true;

    return user?.roles.findIndex((role) => role.toUpperCase() === machine.toUpperCase()) !== -1;
  }

  isAuth() {
    return this.user !== null;
  }

  isOwner(user: User<StreamChatGenerics>) {
    if (!this.isAuth()) return false;
    return user.id === this.user?.id;
  }

  isOwnerId(userId: number) {
    if (!this.isAuth()) return false;
    return userId === this.user?.id;
  }

  isGuest(user?: User<StreamChatGenerics>) {
    return this.hasRole('guest', user);
  }

  hasPermission(perm: string) {
    if (!this.user) return false;
    if (this.user.id === 1) return true;
    if (!this.user.permissions) return false;
    const permissions: string[] = (this.user as OwnUserResponse).permissions || [];
    return permissions.includes(perm);
  }

  /**
   * sendUserCustomEvent - Send a custom event to a user
   *
   * @param {string} targetUserID target user id
   * @param {UserCustomEvent} event for example {type: 'friendship-request'}
   *
   * @return {Promise<APIResponse>} The Server Response
   */
  async sendUserCustomEvent(targetUserID: string, event: UserCustomEvent) {
    return await this.post<APIResponse>(`${this.baseURL}/users/${targetUserID}/event`, {
      event,
    });
  }

  createBlockList(blockList: BlockList) {
    return this.post<APIResponse>(`${this.baseURL}/blocklists`, blockList);
  }

  listBlockLists() {
    return this.get<APIResponse & { blocklists: BlockListResponse[] }>(`${this.baseURL}/blocklists`);
  }

  getBlockList(name: string) {
    return this.get<APIResponse & { blocklist: BlockListResponse }>(`${this.baseURL}/blocklists/${name}`);
  }

  updateBlockList(name: string, data: { words: string[] }) {
    return this.put<APIResponse>(`${this.baseURL}/blocklists/${name}`, data);
  }

  deleteBlockList(name: string) {
    return this.delete<APIResponse>(`${this.baseURL}/blocklists/${name}`);
  }

  /**
   * enrichURL - Get OpenGraph data of the given link
   *
   * @param {string} url link
   * @return {OGAttachment} OG Attachment
   */
  async enrichURL(url: string) {
    return this.get<APIResponse & OGAttachment>(this.baseURL + `/og`, { url });
  }

  /**
   * deleteUsers - Batch Delete Users
   *
   * @param {string[]} user_ids which users to delete
   * @param {DeleteUserOptions} options Configuration how to delete users
   *
   * @return {TaskResponse} A task ID
   */
  async deleteUsers(user_ids: string[], options: DeleteUserOptions) {
    if (options?.user !== 'soft' && options?.user !== 'hard') {
      throw new Error('Invalid delete user options. user must be one of [soft hard]');
    }
    if (options.messages !== undefined && options.messages !== 'soft' && options.messages !== 'hard') {
      throw new Error('Invalid delete user options. messages must be one of [soft hard]');
    }
    if (options.conversations !== undefined && options.conversations !== 'soft' && options.conversations !== 'hard') {
      throw new Error('Invalid delete user options. conversations must be one of [soft hard]');
    }
    return await this.post<APIResponse & TaskResponse>(this.baseURL + `/users/delete`, {
      user_ids,
      ...options,
    });
  }

  /** deleteTrain - delete a custom text
   *
   * @param {string} id the text id
   * @returns {Promise<APIResponse>}
   */
  deleteTrain(id: string) {
    return this.delete<APIResponse>(`${this.baseURL}/train/${id}`);
  }

  async testMessage(text: string, machine_learning?: MachineLearning) {
    const data = await this.post<
      APIResponse & { action?: 'blocked' | 'flagged' | 'none'; result: AiModerationResultResponse[] }
    >(this.baseURL + '/moderation/test-message', {
      text,
      machine_learning,
    });

    return data;
  }

  async testTrain(text: string, machine_learning?: MachineLearning) {
    return await this.queryTrains({}, [], { text, machine_learning });
  }

  async queryTrain(data: TrainResponse) {
    return await this.queryTrains({}, [], { data });
  }

  async training(training: boolean = true, machine_learning?: MachineLearning) {
    return await this.queryTrains({}, [], { training, machine_learning });
  }

  /**
   * queryTrains - Query texts
   *
   * @param {TrainFilters} filterConditions MongoDB style filter conditions
   * @param {TrainSort} sort Sort options
   * @param {TrainQueryOptions} options Option object
   *
   * @return {Promise<TrainDataAPIResponse>} User Query Response
   */
  async queryTrains(filterConditions: TrainFilters, sort: TrainSort = [], options: TrainQueryOptions = {}) {
    // Return a list of texts
    const data = await this.post<TrainDataAPIResponse>(this.baseURL + '/train', {
      filter_conditions: filterConditions,
      sort: normalizeQuerySort(sort),
      ...options,
    });

    return data;
  }
}
