export const Unknown = () => (
  <svg
    width="100px"
    height="100px"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M993.763 493.538v35c0 13.333-6.04 26.664-18.135 37.139-140.15 121.42-280.35 242.794-420.487 364.219-11.817 10.238-25.814 15.501-42.456 15.501v-35c16.642 0 30.639-5.264 42.456-15.501 140.138-121.425 280.335-242.802 420.487-364.218 12.095-10.476 18.135-23.804 18.135-37.14z"
      fill="#88AFD5"
    />
    <path
      d="M30.239 528.367v-3.5-1.75-3.5-3.5-1.75-3.5-3.5-1.75-3.5-3.5-1.75-3.5c0 14.707 6.701 29.313 19.037 40.019 138.449 120.064 277.049 239.996 415.562 360.02 13.002 11.26 28.74 16.466 47.852 16.994v35c-19.109-0.528-34.85-5.734-47.852-16.994C326.325 808.382 187.725 688.45 49.276 568.386c-12.337-10.705-19.037-25.312-19.037-40.019z"
      fill="#88AFD5"
    />
    <path
      d="M510.786 76.601c16.263 0 32.546 5.362 44.946 16.097 139.949 121.188 279.9 242.376 419.819 363.586 24.241 20.995 24.295 53.413 0.078 74.397-140.15 121.418-280.351 242.795-420.488 364.217-11.816 10.239-25.813 15.502-42.454 15.502-19.109-0.528-34.85-5.734-47.853-16.994-138.51-120.024-277.11-239.956-415.559-360.02-19.581-16.988-24.96-43.81-11.895-65.251 3.919-6.438 8.669-11.829 14.465-16.849C189.954 331.734 328.024 212.152 466.107 92.567c12.296-10.639 28.478-15.966 44.679-15.966z"
      fill="#C8DEF3"
    />
    <path
      d="M733.354 430.812v16.8c-56.7-40.504-112.906-80.661-169.869-121.357v-16.8c56.963 40.696 113.169 80.852 169.869 121.357z"
      fill="#3269A3"
    />
    <path
      d="M733.354 430.812v16.8c-56.7-40.504-112.906-80.661-169.869-121.357v-16.8c56.963 40.696 113.169 80.852 169.869 121.357"
      fill="#3269A3"
    />
    <path
      d="M563.489 342.692v16.8c0-9.374-0.004-18.748-0.004-28.122v-16.8c0 9.375 0.004 18.747 0.004 28.122z"
      fill="#3269A3"
    />
    <path
      d="M563.489 342.692v16.8c0-9.374-0.004-18.748-0.004-28.122v-16.8c0 9.375 0.004 18.747 0.004 28.122"
      fill="#3269A3"
    />
    <path
      d="M538.209 331.406v-16.8c0 14.247 0.017 28.493 0.017 42.738v16.8c-0.002-14.247-0.017-28.491-0.017-42.738z"
      fill="#3269A3"
    />
    <path
      d="M538.224 357.344v16.8c0-14.247-0.016-28.493-0.016-42.738v-16.8c0.001 14.247 0.016 28.492 0.016 42.738"
      fill="#3269A3"
    />
    <path
      d="M563.476 387.614c-0.005 5.463-1.74 9.134-5.409 11.21 0.087-5.673 0.278-11.344 0.366-17.018 3.418-2.109 5.039-5.715 5.045-10.994 0.011-9.376 0.014-18.749 0.014-28.122v16.8c-0.004 9.375-0.007 18.748-0.016 28.124z"
      fill="#3269A3"
    />
    <path
      d="M563.488 342.692v16.8c0 9.372-0.003 18.746-0.014 28.121-0.001 0.476-0.014 0.938-0.04 1.387v-16.8c0.027-0.448 0.039-0.911 0.04-1.387 0.011-9.375 0.014-18.748 0.014-28.121"
      fill="#3269A3"
    />
    <path
      d="M563.435 372.201v16.8c-0.192 3.191-1.051 5.7-2.63 7.576v-16.8c1.579-1.877 2.44-4.386 2.63-7.576M560.805 379.776v16.8a9.803 9.803 0 0 1-2.228 1.938v-16.799a9.761 9.761 0 0 0 2.228-1.939"
      fill="#3269A3"
    />
    <path
      d="M558.58 381.715v16.8a12.38 12.38 0 0 1-2.41 1.178v-16.8a12.267 12.267 0 0 0 2.41-1.178"
      fill="#3269A3"
    />
    <path d="M766.063 431.008v0z" fill="#3269A3" />
    <path d="M766.063 431.008v19.518-16.8-2.718" fill="#3269A3" />
    <path
      d="M538.105 500.464v16.8c-0.859-0.218-1.397-0.479-1.926-0.479v-16.8c0.526-0.001 1.066 0.261 1.926 0.479z"
      fill="#3269A3"
    />
    <path
      d="M538.105 500.464v16.8c-0.859-0.218-1.397-0.479-1.926-0.479v-16.8c0.526-0.001 1.066 0.261 1.926 0.479"
      fill="#3269A3"
    />
    <path
      d="M563.551 505.132v16.8c0-4.717-0.001-9.437-0.004-14.154-0.155-8.612-4.795-12.931-14.069-12.977V478c9.274 0.046 13.914 4.366 14.069 12.978 0.003 4.716 0.004 9.436 0.004 14.154z"
      fill="#3269A3"
    />
    <path
      d="M563.551 505.132v16.8c0-4.717-0.001-9.437-0.004-14.154-0.155-8.612-4.795-12.931-14.069-12.977V478c9.274 0.046 13.914 4.366 14.069 12.978 0.003 4.716 0.004 9.436 0.004 14.154"
      fill="#3269A3"
    />
    <path d="M563.551 510.672V521.932v-16.8 5.54z" fill="#3269A3" />
    <path d="M563.551 510.672V521.932v-16.8 5.54" fill="#3269A3" />
    <path
      d="M563.551 527.999c0 12.137-0.004 24.272-0.004 36.407v-16.8c0-12.134 0.004-24.271 0.004-36.406v16.799z"
      fill="#3269A3"
    />
    <path
      d="M563.551 511.214v16.8c0 12.131-0.004 24.262-0.004 36.389v-16.8c0-12.126 0.004-24.257 0.004-36.389"
      fill="#3269A3"
    />
    <path
      d="M733.354 430.812v16.8a976440.883 976440.883 0 0 0-169.808 121.743v-16.801c57.023-40.889 113.251-81.201 169.808-121.742z"
      fill="#3269A3"
    />
    <path
      d="M733.354 430.812v16.8a976440.883 976440.883 0 0 0-169.808 121.743v-16.801c57.023-40.889 113.251-81.201 169.808-121.742"
      fill="#3269A3"
    />
    <path
      d="M549.48 477.999v16.8c-22.705 0.651-44.407 0.747-65.907 2.494-42.024 3.421-78.795 17.581-109.55 42.958-13.669 11.279-25.746 23.717-36.671 37.021-1.099 1.327-2.27 2.61-3.397 3.92v-16.8c1.128-1.311 2.298-2.593 3.397-3.92 10.927-13.305 23.002-25.743 36.671-37.022 30.753-25.376 67.525-39.535 109.55-42.957 21.499-1.748 43.202-1.844 65.907-2.494z"
      fill="#3269A3"
    />
    <path
      d="M549.48 477.999v16.8c-22.705 0.651-44.407 0.747-65.907 2.494-4.253 0.346-8.452 0.801-12.596 1.369v-16.8a231.206 231.206 0 0 1 12.596-1.368c21.499-1.749 43.202-1.845 65.907-2.495M470.976 481.861v16.8c-15.017 2.053-29.316 5.558-42.863 10.534v-16.8c13.547-4.977 27.846-8.48 42.863-10.534"
      fill="#3269A3"
    />
    <path
      d="M428.115 492.395v16.8c-12.592 4.626-24.53 10.528-35.788 17.72v-16.8c11.256-7.193 23.196-13.093 35.788-17.72M392.327 510.115v16.8a186.286 186.286 0 0 0-18.304 13.334c-12.363 10.199-23.423 21.351-33.508 33.232v-16.8c10.085-11.882 21.145-23.031 33.508-33.233a186.603 186.603 0 0 1 18.304-13.333M340.515 556.683v16.801a317.106 317.106 0 0 0-3.164 3.788l-0.481 0.574v-16.8l0.481-0.574a302.082 302.082 0 0 1 3.164-3.789"
      fill="#3269A3"
    />
    <path
      d="M336.872 561.046v16.8c-0.957 1.125-1.953 2.226-2.917 3.346v-16.8c0.964-1.121 1.96-2.221 2.917-3.346"
      fill="#3269A3"
    />
    <path
      d="M363.015 473.644c-22.559 33.085-28.365 69.705-29.06 107.545v-16.8c0.696-37.841 6.501-74.462 29.06-107.545 16.623-24.374 40.371-41.867 69.986-53.531 34.303-13.513 70.781-17.86 107.996-19.047 2.541-0.079 5.072-0.115 7.61-0.167 4.188-0.08 7.443-0.823 9.825-2.293-0.088 5.673-0.277 11.344-0.366 17.018-2.347 1.327-5.48 2.001-9.459 2.077-2.538 0.05-5.067 0.087-7.61 0.166-37.215 1.188-73.693 5.534-107.996 19.047a186.698 186.698 0 0 0-16.128 7.231 42.75 42.75 0 0 1 1.936-6.472c1.255-3.205 2.12-4.9 3.373-7.089-1.252 2.189-2.118 3.884-3.373 7.089a42.196 42.196 0 0 0-1.936 6.472c-22.195 11.279-40.372 26.524-53.858 46.299z"
      fill="#3269A3"
    />
    <path
      d="M558.58 381.715v16.8a12.38 12.38 0 0 1-2.41 1.178v-16.8a12.267 12.267 0 0 0 2.41-1.178M556.17 382.895v16.8c-1.179 0.433-2.505 0.748-3.984 0.946v-16.8c1.479-0.201 2.805-0.516 3.984-0.946"
      fill="#3269A3"
    />
    <path
      d="M552.186 383.839v16.8a31.073 31.073 0 0 1-3.58 0.261c-2.538 0.05-5.067 0.087-7.61 0.166-15.832 0.506-31.53 1.583-46.986 3.694v-16.801c15.453-2.111 31.154-3.188 46.986-3.692 2.542-0.079 5.072-0.115 7.61-0.167a30.818 30.818 0 0 0 3.58-0.261"
      fill="#3269A3"
    />
    <path
      d="M494.011 387.96v16.8c-19.194 2.623-38.012 6.839-56.241 13.538v-16.8c18.23-6.698 37.046-10.916 56.241-13.538M437.77 401.499v16.8c-1.594 0.585-3.184 1.19-4.77 1.815-10.835 4.266-20.884 9.313-30.086 15.192v-16.8c9.203-5.879 19.251-10.926 30.086-15.192 1.587-0.626 3.176-1.23 4.77-1.815"
      fill="#3269A3"
    />
    <path
      d="M402.915 418.507v16.8c-11.963 7.643-22.495 16.689-31.459 27.256v-16.8c8.964-10.565 19.496-19.614 31.459-27.256M371.456 445.763v16.8a131.943 131.943 0 0 0-8.441 11.083c-19.592 28.733-26.548 60.133-28.495 92.688v-16.801c1.947-32.554 8.902-63.954 28.495-92.687a132.106 132.106 0 0 1 8.441-11.083M334.52 549.531v16.8a365.64 365.64 0 0 0-0.565 14.858v-16.8c0.09-4.974 0.273-9.929 0.565-14.858"
      fill="#3269A3"
    />
    <path
      d="M563.485 309.455c56.963 40.696 113.169 80.853 169.869 121.357a1248608.25 1248608.25 0 0 0-169.808 121.742v-4.947c0-14.156 0.006-28.316 0.004-42.475 0-4.717-0.001-9.436-0.004-14.154-0.155-8.612-4.795-12.931-14.069-12.978-22.704 0.651-44.405 0.747-65.905 2.494-42.025 3.421-78.795 17.581-109.55 42.958-13.669 11.28-25.746 23.718-36.67 37.021-1.1 1.328-2.27 2.61-3.397 3.92 0.696-37.841 6.501-74.462 29.061-107.547 16.623-24.373 40.371-41.866 69.986-53.531 34.302-13.511 70.781-17.858 107.997-19.046 2.54-0.079 5.071-0.115 7.612-0.167 10.198-0.193 14.856-4.33 14.867-13.287 0.01-9.376 0.014-18.749 0.014-28.122 0-9.374-0.004-18.748-0.004-28.123-0.003-1.388-0.003-2.778-0.003-5.115z"
      fill="#88AFD5"
    />
    <path
      d="M538.114 572.248v16.801c-0.012-22.374-0.012-44.739-0.012-67.113v-16.799c0.003 22.373 0.003 44.737 0.012 67.111z"
      fill="#3269A3"
    />
    <path
      d="M538.114 572.248v16.801c-0.012-22.374-0.012-44.739-0.012-67.113v-16.799c0.003 22.373 0.003 44.737 0.012 67.111"
      fill="#3269A3"
    />
    <path
      d="M766.063 433.728v16.8c-2.922 2.837-5.472 6.067-8.82 8.468-65.259 46.893-130.585 93.713-196.034 140.404-2.902 2.071-7.017 3.589-10.735 3.922v-16.801c3.719-0.332 7.833-1.85 10.735-3.921 65.449-46.692 130.775-93.51 196.034-140.406 3.347-2.399 5.899-5.629 8.82-8.466z"
      fill="#3269A3"
    />
    <path
      d="M766.063 433.728v16.8c-2.922 2.837-5.472 6.067-8.82 8.468-65.259 46.893-130.585 93.713-196.034 140.404-0.237 0.17-0.486 0.337-0.738 0.498v-16.8c0.253-0.161 0.501-0.328 0.738-0.498 65.449-46.692 130.775-93.51 196.034-140.406 3.347-2.399 5.899-5.629 8.82-8.466M560.47 583.099v16.8a22.456 22.456 0 0 1-4.297 2.105v-16.8a22.456 22.456 0 0 0 4.297-2.105M556.173 585.204v16.8c-1.57 0.575-3.213 0.997-4.811 1.216v-16.801a23.38 23.38 0 0 0 4.811-1.215M551.362 586.419v16.801a17.19 17.19 0 0 1-0.89 0.101v-16.8c0.296-0.027 0.592-0.059 0.89-0.102"
      fill="#3269A3"
    />
    <path
      d="M550.473 586.521v16.8c-6.915 0.048-11.386-4.052-12.099-9.246-0.23-1.658-0.261-3.351-0.261-5.027v-16.8c0 1.676 0.028 3.369 0.261 5.027 0.714 5.195 5.184 9.296 12.099 9.246z"
      fill="#3269A3"
    />
    <path
      d="M550.473 586.521v16.8c-6.915 0.048-11.386-4.052-12.099-9.246-0.23-1.658-0.261-3.351-0.261-5.027v-16.8c0 1.676 0.028 3.369 0.261 5.027 0.714 5.195 5.184 9.296 12.099 9.246"
      fill="#3269A3"
    />
    <path
      d="M757.869 419.858c3.174 2.364 5.482 5.596 8.194 8.431v5.438c-2.922 2.837-5.472 6.067-8.82 8.467-65.259 46.895-130.585 93.714-196.034 140.406-2.902 2.071-7.017 3.589-10.735 3.921-6.915 0.048-11.386-4.053-12.098-9.246-0.231-1.658-0.262-3.352-0.262-5.027-0.012-22.374-0.012-44.738-0.012-67.114v-4.67c-0.857-0.219-1.396-0.48-1.925-0.48-22.124 0.439-44.2 0.498-66.044 4.114-30.671 5.071-56.91 17.266-79.317 35.902-21.956 18.245-39.041 39.686-54.433 62.233-1.754 2.56-3.488 5.124-5.291 7.646-2.57 3.579-6.148 5.563-10.821 5.563a17.75 17.75 0 0 1-1.578-0.071c-5.272-0.48-8.66-3.117-10.593-7.29-0.212-0.462-0.546-0.872-0.827-1.309v-45.009c0.583-6.277 1.149-12.555 1.754-18.83 2.007-20.914 5.817-41.537 13.438-61.533 18.426-48.319 54.682-82.207 109.257-101.236 30.058-10.484 61.498-15.4 93.69-17.154 4.135-0.227 8.268-0.532 12.813-0.83v-4.836c0-23.15-0.038-46.301 0.011-69.451 0.018-7.597 4.687-12.55 11.813-12.55 0.721 0 1.463 0.051 2.231 0.154 3.032 0.41 6.178 1.974 8.616 3.701 54.906 39.073 109.721 78.244 164.557 117.396 10.823 7.723 21.772 15.335 32.416 23.264zM563.547 552.556a918915.37 918915.37 0 0 1 169.808-121.744c-56.7-40.504-112.906-80.661-169.869-121.357v5.115c0 9.375 0.003 18.749 0.003 28.123 0 9.373-0.003 18.746-0.014 28.122-0.01 8.956-4.668 13.096-14.868 13.286-2.539 0.052-5.068 0.088-7.611 0.167-37.215 1.187-73.692 5.534-107.995 19.047-29.616 11.664-53.364 29.157-69.986 53.531-22.559 33.084-28.365 69.705-29.06 107.545 1.128-1.309 2.298-2.592 3.397-3.918 10.925-13.306 23.001-25.744 36.67-37.023 30.753-25.376 67.525-39.535 109.55-42.957 21.5-1.748 43.203-1.843 64.823-2.48 10.356 0.034 14.996 4.353 15.147 12.57 0.007 5.113 0.007 9.833 0.007 14.551 0.003 14.158-0.003 28.318-0.003 42.475l0.001 4.947"
      fill="#427BAE"
    />
    <path
      d="M536.18 499.984v16.8c-22.125 0.439-44.201 0.498-66.044 4.114-30.673 5.069-56.911 17.265-79.317 35.902-21.956 18.245-39.042 39.686-54.433 62.232-1.754 2.56-3.488 5.125-5.291 7.647-2.57 3.579-6.148 5.563-10.821 5.563a17.75 17.75 0 0 1-1.578-0.071c-5.272-0.479-8.66-3.117-10.593-7.289-0.212-0.463-0.545-0.873-0.827-1.309v-16.8c0.282 0.436 0.615 0.845 0.827 1.308 1.935 4.173 5.323 6.811 10.593 7.29 0.539 0.048 1.065 0.071 1.578 0.071 4.672 0 8.251-1.983 10.821-5.563 1.805-2.522 3.537-5.087 5.291-7.646 15.392-22.548 32.478-43.987 54.433-62.232 22.407-18.638 48.647-30.832 79.317-35.903 21.843-3.615 43.919-3.675 66.044-4.114z"
      fill="#3269A3"
    />
    <path
      d="M536.18 499.984v16.8c-20.082 0.396-40.121 0.482-59.986 3.196v-16.799c19.863-2.713 39.904-2.8 59.986-3.197M476.193 503.184v16.8c-2.02 0.277-4.042 0.58-6.06 0.916-11.068 1.829-21.562 4.587-31.505 8.241v-16.8c9.945-3.654 20.437-6.412 31.505-8.241 2.019-0.337 4.04-0.64 6.06-0.916M438.628 512.34v16.8a159.895 159.895 0 0 0-31.041 15.385v-16.8a159.81 159.81 0 0 1 31.041-15.385"
      fill="#3269A3"
    />
    <path
      d="M407.588 527.725v16.8a165.898 165.898 0 0 0-16.771 12.276c-11.253 9.353-21.227 19.544-30.35 30.293v-16.801c9.124-10.748 19.098-20.939 30.35-30.292a166.052 166.052 0 0 1 16.771-12.276M360.466 570.293v16.801c-8.673 10.224-16.58 20.95-24.083 31.939-1.754 2.56-3.488 5.125-5.291 7.647a15.4 15.4 0 0 1-0.786 1.006v-16.8c0.272-0.318 0.532-0.655 0.786-1.007 1.805-2.522 3.537-5.087 5.291-7.646 7.504-10.99 15.409-21.717 24.083-31.94M330.306 610.887v16.8a13.113 13.113 0 0 1-2.943 2.599v-16.8a13.138 13.138 0 0 0 2.943-2.599"
      fill="#3269A3"
    />
    <path
      d="M327.364 613.485v16.8c-0.73 0.467-1.504 0.851-2.329 1.155v-16.8a11.862 11.862 0 0 0 2.329-1.155"
      fill="#3269A3"
    />
    <path
      d="M325.036 614.641v16.8c-0.852 0.313-1.754 0.538-2.711 0.668v-16.801c0.955-0.13 1.858-0.354 2.711-0.667M322.325 615.308v16.801c-0.659 0.09-1.343 0.135-2.052 0.135a17.75 17.75 0 0 1-1.578-0.071c-5.272-0.479-8.66-3.117-10.593-7.289-0.212-0.463-0.545-0.873-0.827-1.309v-16.8c0.282 0.436 0.615 0.845 0.827 1.308 1.935 4.173 5.323 6.811 10.593 7.29 0.539 0.048 1.065 0.071 1.578 0.071a15.34 15.34 0 0 0 2.052-0.136"
      fill="#3269A3"
    />
  </svg>
);
