import { Link, useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { NodeData, NodesData, getBody, getTitle } from "../NodeView";
import { useTranslation } from "react-i18next";
import { MaleIcon } from "../icons/MaleIcon";
import { FemaleIcon } from "../icons/FemaleIcon";
import { Unknown } from "../icons/Unknown";
import { useClient } from "../../hooks/useClient/useClient";

const NodeRoot = ({ nodes }: { nodes: NodeData[] }) => {
  const { i18n } = useTranslation();

  const icon = (node: NodeData) => {
    const title = getTitle(node, "en").toLowerCase();
    if (title === "men") {
      return <MaleIcon />;
    }
    if (title === "woman") {
      return <FemaleIcon />;
    }
    return <Unknown />;
  };

  return (
    <section className="gap-2 mx-2 flex justify-center">
      {nodes.map((node) => (
        <div
          key={node.id}
          className="my-8 rounded shadow-lg cursor-pointer shadow-gray-200 dark:shadow-gray-900 bg-white dark:bg-gray-800 duration-300 hover:-translate-y-1"
        >
          <Link to={`/node/${node.id}`}>
            <figure>
              {icon(node)}
              <figcaption className="p-4">
                <p className="text-lg mb-4 font-bold leading-relaxed text-gray-800 dark:text-gray-300">
                  {getTitle(node, i18n.language)}
                </p>
              </figcaption>
            </figure>
          </Link>
        </div>
      ))}
    </section>
  );
};

export const NodeScreen = () => {
  let { nodeId } = useParams();
  const [nodes, setNodes] = useState<NodeData[]>([] as NodeData[]);
  const [result, setResult] = useState<NodeData | null>(null);
  const [cards, setCards] = useState<NodeData[]>([]);
  const [root, setRoot] = useState<NodeData[]>([]);
  const { i18n } = useTranslation();

  const [loading, setLoading] = useState(true);

  const { client } = useClient("1", "https://center.drmeshari.com");

  const getParentRecursive = useCallback(
    (cards: NodeData[], id: number) => {
      let current = nodes.find((el) => el?.id === id);
      if (current) {
        cards.unshift(current);
        if (current.parent_id) {
          getParentRecursive(cards, current.parent_id);
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    [nodes]
  );

  const handleOutside = useCallback(
    (place: number) => {
      setResult(null);
      let entrance = nodes.find((el) => el.id === place);
      if (entrance) {
        const cards: NodeData[] = [];

        cards.push(entrance);
        if (entrance.parent_id) {
          getParentRecursive(cards, entrance.parent_id);
        }

        if (cards[cards.length - 1].children === undefined) {
          setResult(cards[cards.length - 1]);
        } else {
          for (let item of cards[cards.length - 1].children!) {
            cards.push(item);
          }
        }

        setCards(cards);
      }
    },
    [getParentRecursive, nodes]
  );

  useEffect(() => {
    setLoading(true);
    client
      .get<NodesData>("nodes")
      .then((res) => {
        setLoading(false);
        setNodes(res.nodes);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  }, [client]);

  useEffect(() => {
    setResult(null);
    setCards([]);
    const root: NodeData[] = [];
    for (let node of nodes) {
      if (!node?.parent_id) {
        root.push(node);
      }
    }
    setRoot(root);

    if (!nodeId) {
      /*const cards: NodeData[] = [];
      for (let node of nodes) {
        if (!node?.parent_id) {
          cards.push(node);
        }
      }
      setCards(cards);*/
    } else {
      handleOutside(Number(nodeId));
    }
  }, [handleOutside, nodeId, nodes]);

  if (loading) {
    return (
      <div className="mb-5 mt-10 sm:mt-12 border border-blue-300 shadow rounded-md p-4 max-w-sm w-full mx-auto">
        <div className="animate-pulse flex space-x-4">
          <div className="rounded-full bg-slate-200 h-10 w-10"></div>
          <div className="flex-1 space-y-6 py-1">
            <div className="h-2 bg-slate-200 rounded"></div>
            <div className="space-y-3">
              <div className="grid grid-cols-3 gap-4">
                <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                <div className="h-2 bg-slate-200 rounded col-span-1"></div>
              </div>
              <div className="h-2 bg-slate-200 rounded"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <section className="px-8 mb-5 mt-10 sm:mt-12">
      <NodeRoot nodes={root} />
      {cards.map((item) => (
        <Link
          key={item.id}
          to={`/node/${item.id}`}
          className="flex items-center w-full space-x-3 mt-2 px-4 h-12 bg-white ring-1 ring-slate-900/10 hover:ring-slate-300 focus:outline-none focus:ring-2 focus:ring-sky-500 shadow-sm rounded-lg text-slate-400 dark:bg-slate-800 dark:ring-0 dark:text-slate-300 dark:highlight-white/5 dark:hover:bg-slate-700"
        >
          <span
            className={`text-center ${
              item?.children !== undefined ? "font-bold" : ""
            }`}
          >
            {getTitle(item, i18n.language)}
          </span>
        </Link>
      ))}

      {result && (
        <div className="whitespace-pre-wrap flex-auto mt-2 relative block text-slate-400 dark:bg-slate-800 overflow-auto rounded-lg shadow-sm p-4">
          {getBody(result, i18n.language)}
        </div>
      )}
    </section>
  );
};
