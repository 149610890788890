export const AboutScreen = () => {
  return (
    <main className="mt-16 sm:mt-20 relative">
      <div className="relative max-w-3xl px-4 sm:px-6 lg:px-8 mx-auto text-right">
        <p>
          تحت اشراف رئيس الاداره المركزيه للرعاية الصحية الاولية : د.دينا الضبيب
        </p>
        <p>فكرة واعداد : د.مروه العسعوسي</p>
        <p>تنفيذ وبرمجة وديزاين : د.مشاري عوده</p>
      </div>
    </main>
  );
};
