import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { EVENT_MAP } from './events';

/**
 * Utility Types
 */

export type ArrayOneOrMore<T> = {
  0: T;
} & Array<T>;

export type ArrayTwoOrMore<T> = {
  0: T;
  1: T;
} & Array<T>;

export type KnownKeys<T> = {
  [K in keyof T]: string extends K ? never : number extends K ? never : K;
} extends { [_ in keyof T]: infer U }
  ? U
  : never;

export type RequireAtLeastOne<T> = {
  [K in keyof T]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<keyof T, K>>>;
}[keyof T];

export type RequireOnlyOne<T, Keys extends keyof T = keyof T> = Pick<T, Exclude<keyof T, Keys>> &
  {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Record<Exclude<Keys, K>, undefined>>;
  }[Keys];

/* Unknown Record */
export type UR = Record<string, unknown>;
export type UnknownType = UR; //alias to avoid breaking change

export type DefaultGenerics = {
  attachmentType: UR;
  channelType: UR;
  commandType: LiteralStringForUnion;
  eventType: UR;
  messageType: UR;
  reactionType: UR;
  userType: UR;
};

export type ExtendableGenerics = {
  attachmentType: UR;
  channelType: UR;
  commandType: string;
  eventType: UR;
  messageType: UR;
  reactionType: UR;
  userType: UR;
};

export type Unpacked<T> = T extends (infer U)[]
  ? U // eslint-disable-next-line @typescript-eslint/no-explicit-any
  : T extends (...args: any[]) => infer U
  ? U
  : T extends Promise<infer U>
  ? U
  : T;

/**
 * Response Types
 */

export type APIResponse = {
  duration: string;
};

export type MemberOrInvite = {
  user_id?: number;
  username?: string;
  channel_role?: string;
};

export type AppSettingsAPIResponse<StreamChatGenerics extends ExtendableGenerics = DefaultGenerics> = APIResponse & {
  app?: {
    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    call_types: any;
    reminders_interval: number;
    agora_options?: AgoraOptions | null;
    async_moderation_config?: AsyncModerationOptions;
    async_url_enrich_enabled?: boolean;
    auto_translation_enabled?: boolean;
    before_message_send_hook_url?: string;
    campaign_enabled?: boolean;
    cdn_expiration_seconds?: number;
    custom_action_handler_url?: string;
    disable_auth_checks?: boolean;
    disable_permissions_checks?: boolean;
    enforce_unique_usernames?: 'no' | 'app' | 'team';
    file_upload_config?: FileUploadConfig;
    grants?: Record<string, string[]>;
    hms_options?: HMSOptions | null;
    image_moderation_enabled?: boolean;
    image_upload_config?: FileUploadConfig;
    multi_tenant_enabled?: boolean;
    name?: string;
    organization?: string;
    permission_version?: string;
    policies?: Record<string, Policy[]>;
    push_notifications?: {
      offline_only: boolean;
      version: string;
      apn?: APNConfig;
      firebase?: FirebaseConfig;
      huawei?: HuaweiConfig;
      xiaomi?: XiaomiConfig;
    };
    revoke_tokens_issued_before?: string | null;
    search_backend?: 'disabled' | 'elasticsearch' | 'postgres';
    sqs_key?: string;
    sqs_secret?: string;
    sqs_url?: string;
    suspended?: boolean;
    suspended_explanation?: string;
    user_search_disallowed_roles?: string[] | null;
    video_provider?: string;
    webhook_events?: Array<string>;
    webhook_url?: string;
    countries?: CountryResponse[];
    currency_types?: string;
  };
};

export type ModerationResult = {
  action: string;
  created_at: string;
  message_id: string;
  updated_at: string;
  user_bad_karma: boolean;
  user_karma: number;
  blocked_word?: string;
  blocklist_name?: string;
  moderated_by?: string;
};

export type AutomodDetails = {
  action?: string;
  image_labels?: Array<string>;
  original_message_type?: string;
  result?: ModerationResult;
};

export type FlagDetails = {
  automod?: AutomodDetails;
};

export type AiModerationResultResponse = {
  category: string;
  score: number;
  best?: boolean;
};

export type AiModerationResultsResponse = {
  name?: string;
  result?: AiModerationResultResponse;
  results?: AiModerationResultResponse[];
  behavior?: string;
  karma?: number;
  karma_status?: string;
  blocked_word?: string;
  blocklist_name?: string;
};

export type Flag<StreamChatGenerics extends ExtendableGenerics = DefaultGenerics> = {
  id: number;
  created_at: string;
  created_by?: UserResponse<StreamChatGenerics>;
  moderated_by?: UserResponse<StreamChatGenerics>;
  moderated_action?: string;
  created_by_automod: boolean;
  ai_moderation_response?: AiModerationResultsResponse;
  text?: string;
  updated_at: string;
  approved_at?: string;
  rejected_at?: string;
  target_user_id?: number;
  target_user?: UserResponse<StreamChatGenerics>;
  review?: string;
};

export type FlagsResponse<StreamChatGenerics extends ExtendableGenerics = DefaultGenerics> = APIResponse & {
  flags?: Flag<StreamChatGenerics>[];
  pagination?: PaginationResponse;
};

export type FlagReport<StreamChatGenerics extends ExtendableGenerics = DefaultGenerics> = {
  flags_count: number;
  id: string;
  user: UserResponse<StreamChatGenerics>;
  created_at?: string;
  details?: FlagDetails;
  first_reporter?: UserResponse<StreamChatGenerics>;
  review_result?: string;
  reviewed_at?: string;
  reviewed_by?: UserResponse<StreamChatGenerics>;
  updated_at?: string;
};

export type BlockListResponse = BlockList & {
  created_at?: string;
  updated_at?: string;
};

export type CheckSQSResponse = APIResponse & {
  status: string;
  data?: {};
  error?: string;
};

export type EventAPIResponse<StreamChatGenerics extends ExtendableGenerics = DefaultGenerics> = APIResponse & {
  event: Event<StreamChatGenerics>;
};

export type FlagUserResponse<StreamChatGenerics extends ExtendableGenerics = DefaultGenerics> = APIResponse & {
  flag: {
    created_at: string;
    created_by_automod: boolean;
    target_user: UserResponse<StreamChatGenerics>;
    updated_at: string;
    user: UserResponse<StreamChatGenerics>;
    approved_at?: string;
    details?: Object; // Any JSON
    rejected_at?: string;
    reviewed_at?: string;
    reviewed_by?: string;
  };
};

export type PaginationResponse = {
  page: number;
  pages: number;
  total: number;
  size: number;
};

export type UsersResponse<StreamChatGenerics extends ExtendableGenerics = DefaultGenerics> = APIResponse & {
  users: Array<UserResponse<StreamChatGenerics>>;
  pagination: PaginationResponse;
};

export type HistoriesResponse<StreamChatGenerics extends ExtendableGenerics = DefaultGenerics> = APIResponse & {
  histories: Array<HistoryResponse<StreamChatGenerics>>;
  pagination: PaginationResponse;
};

export type HistoryResponse<StreamChatGenerics extends ExtendableGenerics = DefaultGenerics> = {
  id: number;
  user: UserResponse<StreamChatGenerics>;
  target: UserResponse<StreamChatGenerics>;
  violation?: ViolationResponse<StreamChatGenerics>;
  created_at?: string;
  action?: string;
  text?: string;
  old_value?: string;
  new_value?: string;
};

export type ViolationsResponse<StreamChatGenerics extends ExtendableGenerics = DefaultGenerics> = APIResponse & {
  violations: Array<ViolationResponse<StreamChatGenerics>>;
  pagination: PaginationResponse;
};

export type ViolationResponse<StreamChatGenerics extends ExtendableGenerics = DefaultGenerics> = {
  id?: number;
  created_by?: UserResponse<StreamChatGenerics>;
  target?: UserResponse<StreamChatGenerics>;
  user_id?: number;
  username?: string;
  action?: string;
  reason?: string;
  status?: boolean;
  timeout?: number;
  created_at?: string;
  updated_at?: string;
  expires?: string;
};

export type OwnUserBase = {
  invisible?: boolean;
  permissions?: string[];
  needs_update?: boolean;
};

export type OwnUserResponse<
  StreamChatGenerics extends ExtendableGenerics = DefaultGenerics
> = UserResponse<StreamChatGenerics> & OwnUserBase;

export type PermissionAPIResponse = APIResponse & {
  permission?: PermissionAPIObject;
};

export type PermissionsAPIResponse = APIResponse & {
  permissions?: PermissionAPIObject[];
};

type FileEntityResponse = {
  url?: string;
  quality?: string;
}
// Thumb URL(thumb_url) is added considering video attachments as the backend will return the thumbnail in the response.
export type SendFileAPIResponse = APIResponse & { 
  //file: string; 
  //thumb_url?: string 
  id?: string;
  created_at?: string;
  entities?: FileEntityResponse[],
  file_mime_type?: string;
  file_name?: string;
  file_size?: number;
  extension?: string;
  name?: string;
  url?: string;
};

export type UsersAPIResponse<StreamChatGenerics extends ExtendableGenerics = DefaultGenerics> = APIResponse & {
  users: Array<UserResponse<StreamChatGenerics>>;
};

export type UpdateUsersAPIResponse<StreamChatGenerics extends ExtendableGenerics = DefaultGenerics> = APIResponse & {
  users: { [key: string]: UserResponse<StreamChatGenerics> };
};

export type UserResponse<StreamChatGenerics extends ExtendableGenerics = DefaultGenerics> = User<StreamChatGenerics> & {
  banned?: boolean;
  created_at?: string;
  deactivated_at?: string;
  deleted_at?: string;
  language?: TranslationLanguages | '';
  last_active?: string;
  online?: boolean;
  shadow_banned?: boolean;
  updated_at?: string;
  karma?: number;
  karma_status?: string;
};

export type RoleResponse = {
  id: string;
  name: string;
  readOnly: boolean;
  machine: string;
  weight: number;
  permissions: Record<string, boolean>;
  color?: string;
  member_count?: number;
  image?: string;
  fid?: number;
};

export type RolesAPIResponse = APIResponse & {
  roles: Array<RoleResponse>;
};

export type LoginResponse<StreamChatGenerics extends ExtendableGenerics = DefaultGenerics> = {
  user: OwnUserResponse<StreamChatGenerics>;
  token: string;
};

export type FlagsPaginationOptions = {
  limit?: number;
  offset?: number;
  page?: number;
};

export type ReviewFlagOptions = {
  ids: number[];
  review?: string;
  delete?: boolean;
  channel?: boolean;
  violation?: ViolationOptions;
  reject?: boolean;
};

export type HistoryPaginationOptions = Omit<PaginationOptions, 'id_gt' | 'id_gte' | 'id_lt' | 'id_lte'>;
export type ViolationPaginationOptions = Omit<PaginationOptions, 'id_gt' | 'id_gte' | 'id_lt' | 'id_lte'>;

export type RemoveViolationOptions = {
  // channel
  id?: string;
  type?: string;

  violation_id?: number;
  hard?: boolean;

  // user id
  target_id?: string;
  target_username?: string;
  action?: string;
  shadow?: boolean; // all channels
};

export type ViolationOptions = RemoveViolationOptions & {
  reason?: string;
  timeout?: number;
  ip_ban?: boolean;
};

export type CustomPermissionOptions = {
  action: string;
  condition: object;
  id: string;
  name: string;
  description?: string;
  owner?: boolean;
  same_team?: boolean;
};

export type DeactivateUsersOptions = {
  created_by_id?: string;
  mark_messages_deleted?: boolean;
};

export type PaginationOptions = {
  created_at_after?: string | Date;
  created_at_after_or_equal?: string | Date;
  created_at_before?: string | Date;
  created_at_before_or_equal?: string | Date;
  id_gt?: string;
  id_gte?: string;
  id_lt?: string;
  id_lte?: string;
  limit?: number;
  offset?: number;
  page?: number;
};

export type MessagePaginationOptions = PaginationOptions & {
  created_at_around?: string | Date;
  id_around?: string;
};

export type PinnedMessagePaginationOptions = {
  id_around?: string;
  id_gt?: string;
  id_gte?: string;
  id_lt?: string;
  id_lte?: string;
  limit?: number;
  offset?: number;
  pinned_at_after?: string | Date;
  pinned_at_after_or_equal?: string | Date;
  pinned_at_around?: string | Date;
  pinned_at_before?: string | Date;
  pinned_at_before_or_equal?: string | Date;
};

export type QueryMembersOptions = {
  limit?: number;
  offset?: number;
  user_id_gt?: string;
  user_id_gte?: string;
  user_id_lt?: string;
  user_id_lte?: string;
};

export type ReactivateUserOptions = {
  created_by_id?: string;
  name?: string;
  restore_messages?: boolean;
};

export type ReactivateUsersOptions = {
  created_by_id?: string;
  restore_messages?: boolean;
};

export type StreamChatOptions = AxiosRequestConfig & {
  /**
   * Used to disable warnings that are triggered by using connectUser or connectAnonymousUser server-side.
   */
  allowServerSideConnect?: boolean;
  axiosRequestConfig?: AxiosRequestConfig;
  /**
   * Base url to use for API
   * such as https://chat-proxy-dublin.stream-io-api.com
   */
  baseURL?: string;
  browser?: boolean;
  enableInsights?: boolean;
  /** experimental feature, please contact support if you want this feature enabled for you */
  enableWSFallback?: boolean;
  logger?: Logger;
  /**
   * When true, user will be persisted on client. Otherwise if `connectUser` call fails, then you need to
   * call `connectUser` again to retry.
   * This is mainly useful for chat application working in offline mode, where you will need client.user to
   * persist even if connectUser call fails.
   */
  persistUserOnConnectionFailure?: boolean;
  /**
   * When network is recovered, we re-query the active channels on client. But in single query, you can recover
   * only 30 channels. So its not guaranteed that all the channels in activeChannels object have updated state.
   * Thus in UI sdks, state recovery is managed by components themselves, they don't rely on js client for this.
   *
   * `recoverStateOnReconnect` parameter can be used in such cases, to disable state recovery within js client.
   * When false, user/consumer of this client will need to make sure all the channels present on UI by
   * manually calling queryChannels endpoint.
   */
  recoverStateOnReconnect?: boolean;
  warmUp?: boolean;
};

export type UserOptions = {
  limit?: number;
  offset?: number;
  page?: number;
  presence?: boolean;
};

/**
 * Event Types
 */

export type ConnectionChangeEvent = {
  type: EventTypes;
  online?: boolean;
};

export type Event<StreamChatGenerics extends ExtendableGenerics = DefaultGenerics> = StreamChatGenerics['eventType'] & {
  type: EventTypes;
  clear_history?: boolean;
  connection_id?: string;
  created_at?: string;
  hard_delete?: boolean;
  mark_messages_deleted?: boolean;
  me?: OwnUserResponse<StreamChatGenerics>;
  mode?: string;
  online?: boolean;
  parent_id?: string;
  received_at?: string | Date;
  team?: string;
  total_unread_count?: number;
  unread_channels?: number;
  unread_count?: number;
  user?: UserResponse<StreamChatGenerics>;
  user_id?: number;
  watcher_count?: number;
};

export type UserCustomEvent<
  StreamChatGenerics extends ExtendableGenerics = DefaultGenerics
> = StreamChatGenerics['eventType'] & {
  type: string;
};

export type EventHandler<StreamChatGenerics extends ExtendableGenerics = DefaultGenerics> = (
  event: Event<StreamChatGenerics>,
) => void;

export type EventTypes = 'all' | keyof typeof EVENT_MAP;

/**
 * Filter Types
 */

export type AscDesc = 1 | -1;
export enum SortDirection {
  ASC = 1,
  DESC = -1,
}

export type FlagsFilters<StreamChatGenerics extends ExtendableGenerics = DefaultGenerics> = QueryFilters<
  ContainsOperator<Flag<StreamChatGenerics>> & {
    text?:
      | RequireOnlyOne<
          {
            $autocomplete?: Flag<StreamChatGenerics>['text'];
            $q?: Flag<StreamChatGenerics>['text'];
            $r?: TrainResponse['text'];
          } & QueryFilter<Flag<StreamChatGenerics>['text']>
        >
      | PrimitiveFilter<Flag<StreamChatGenerics>['text']>;
  } & {
    automod?:
      | RequireOnlyOne<Pick<QueryFilter<Flag<StreamChatGenerics>['created_by_automod']>, '$eq' | '$ne'>>
      | PrimitiveFilter<Flag<StreamChatGenerics>['created_by_automod']>;
  } & {
    reporter_id?:
      | RequireOnlyOne<Pick<QueryFilter<Flag<StreamChatGenerics>['created_by']>, '$eq' | '$ne' | '$in'>>
      | PrimitiveFilter<Flag<StreamChatGenerics>['created_by']>;
  } & {
    user_id?:
      | RequireOnlyOne<Pick<QueryFilter<Flag<StreamChatGenerics>['target_user_id']>, '$eq' | '$ne' | '$in' | '$nin'>>
      | PrimitiveFilter<Flag<StreamChatGenerics>['target_user_id']>;
  } & {
      [Key in keyof Omit<
        Flag<StreamChatGenerics>,
        'text' | 'created_by_automod' | 'created_by' | 'target_message_id' | 'target_user_id'
      >]?: RequireOnlyOne<QueryFilter<Flag<StreamChatGenerics>[Key]>> | PrimitiveFilter<Flag<StreamChatGenerics>[Key]>;
    }
>;

export type ViolationsOptions = {
  limit?: number;
  offset?: number;
  page?: number;
};

export type ViolationFilters = QueryFilters<
   {
    user_id?:
      | RequireOnlyOne<Pick<QueryFilter<ViolationResponse['user_id']>, '$eq' | '$ne' | '$in' | '$nin'>>
      | PrimitiveFilter<ViolationResponse['user_id']>;
  } & {
    reason?:
      | RequireOnlyOne<
          {
            $q?: ViolationResponse['reason'];
          } & QueryFilter<ViolationResponse['reason']>
        >
      | PrimitiveFilter<ViolationResponse['reason']>;
  } & {
      [Key in keyof Omit<ViolationResponse, 'user_id' | 'reason'>]:
        | RequireOnlyOne<QueryFilter<ViolationResponse[Key]>>
        | PrimitiveFilter<ViolationResponse[Key]>;
    }
>;

export type HistoryFilters = QueryFilters<
  {
    [Key in keyof Omit<HistoryResponse, 'cid'>]:
      | RequireOnlyOne<QueryFilter<HistoryResponse[Key]>>
      | PrimitiveFilter<HistoryResponse[Key]>;
  }
>;

export type ContainsOperator<CustomType = {}> = {
  [Key in keyof CustomType]?: CustomType[Key] extends (infer ContainType)[]
    ?
        | RequireOnlyOne<
            {
              $contains?: ContainType extends object
                ? PrimitiveFilter<RequireAtLeastOne<ContainType>>
                : PrimitiveFilter<ContainType>;
            } & QueryFilter<PrimitiveFilter<ContainType>[]>
          >
        | PrimitiveFilter<PrimitiveFilter<ContainType>[]>
    : RequireOnlyOne<QueryFilter<CustomType[Key]>> | PrimitiveFilter<CustomType[Key]>;
};

export type PrimitiveFilter<ObjectType> = ObjectType | null;

export type QueryFilter<ObjectType = string> = NonNullable<ObjectType> extends string | number | boolean
  ? {
      $eq?: PrimitiveFilter<ObjectType>;
      $exists?: boolean;
      $gt?: PrimitiveFilter<ObjectType>;
      $gte?: PrimitiveFilter<ObjectType>;
      $in?: PrimitiveFilter<ObjectType>[];
      $lt?: PrimitiveFilter<ObjectType>;
      $lte?: PrimitiveFilter<ObjectType>;
      $ne?: PrimitiveFilter<ObjectType>;
      $nin?: PrimitiveFilter<ObjectType>[];
    }
  : {
      $eq?: PrimitiveFilter<ObjectType>;
      $exists?: boolean;
      $in?: PrimitiveFilter<ObjectType>[];
      $ne?: PrimitiveFilter<ObjectType>;
      $nin?: PrimitiveFilter<ObjectType>[];
    };

export type QueryFilters<Operators = {}> = {
  [Key in keyof Operators]?: Operators[Key];
} &
  QueryLogicalOperators<Operators>;

export type QueryLogicalOperators<Operators> = {
  $and?: ArrayOneOrMore<QueryFilters<Operators>>;
  $nor?: ArrayOneOrMore<QueryFilters<Operators>>;
  $or?: ArrayTwoOrMore<QueryFilters<Operators>>;
};

export type UserFilters<StreamChatGenerics extends ExtendableGenerics = DefaultGenerics> = QueryFilters<
  ContainsOperator<StreamChatGenerics['userType']> & {
    name?:
      | RequireOnlyOne<
          { $autocomplete?: UserResponse<StreamChatGenerics>['name'] } & QueryFilter<
            UserResponse<StreamChatGenerics>['name']
          >
        >
      | PrimitiveFilter<UserResponse<StreamChatGenerics>['name']>;
    teams?:
      | RequireOnlyOne<{
          $contains?: PrimitiveFilter<string>;
          $eq?: PrimitiveFilter<UserResponse<StreamChatGenerics>['teams']>;
        }>
      | PrimitiveFilter<UserResponse<StreamChatGenerics>['teams']>;
    username?:
      | RequireOnlyOne<
          { $autocomplete?: UserResponse<StreamChatGenerics>['username'] } & QueryFilter<
            UserResponse<StreamChatGenerics>['username']
          >
        >
      | PrimitiveFilter<UserResponse<StreamChatGenerics>['username']>;
  } & {
      [Key in keyof Omit<
        UserResponse<{
          attachmentType: StreamChatGenerics['attachmentType'];
          channelType: StreamChatGenerics['channelType'];
          commandType: StreamChatGenerics['commandType'];
          eventType: StreamChatGenerics['eventType'];
          messageType: StreamChatGenerics['messageType'];
          reactionType: StreamChatGenerics['reactionType'];
          userType: {};
        }>,
        'name' | 'teams' | 'username'
      >]?:
        | RequireOnlyOne<
            QueryFilter<
              UserResponse<{
                attachmentType: StreamChatGenerics['attachmentType'];
                channelType: StreamChatGenerics['channelType'];
                commandType: StreamChatGenerics['commandType'];
                eventType: StreamChatGenerics['eventType'];
                messageType: StreamChatGenerics['messageType'];
                reactionType: StreamChatGenerics['reactionType'];
                userType: {};
              }>[Key]
            >
          >
        | PrimitiveFilter<
            UserResponse<{
              attachmentType: StreamChatGenerics['attachmentType'];
              channelType: StreamChatGenerics['channelType'];
              commandType: StreamChatGenerics['commandType'];
              eventType: StreamChatGenerics['eventType'];
              messageType: StreamChatGenerics['messageType'];
              reactionType: StreamChatGenerics['reactionType'];
              userType: {};
            }>[Key]
          >;
    }
>;

/**
 * Sort Types
 */
export type SortBase = { created_at?: AscDesc };
export type HistorySort = SortBase | Array<SortBase>;
export type ViolationSort = SortBase | Array<SortBase>;

export type Sort<T> = {
  [P in keyof T]?: AscDesc;
};

export type UserSort<StreamChatGenerics extends ExtendableGenerics = DefaultGenerics> =
  | Sort<UserResponse<StreamChatGenerics>>
  | Array<Sort<UserResponse<StreamChatGenerics>>>;

export type QuerySort<StreamChatGenerics extends ExtendableGenerics = DefaultGenerics> =
  | UserSort<StreamChatGenerics>;

/**
 * Base Types
 */

export type Action = {
  name?: string;
  style?: string;
  text?: string;
  type?: string;
  value?: string;
};

export type AnonUserType = {};

export type APNConfig = {
  auth_key?: string;
  auth_type?: string;
  bundle_id?: string;
  development?: boolean;
  enabled?: boolean;
  host?: string;
  key_id?: string;
  notification_template?: string;
  p12_cert?: string;
  team_id?: string;
};

export type AgoraOptions = {
  app_certificate: string;
  app_id: string;
  role_map?: Record<string, string>;
};

export type HMSOptions = {
  app_access_key: string;
  app_secret: string;
  default_role: string;
  default_room_template: string;
  default_region?: string;
  role_map?: Record<string, string>;
};

export type AsyncModerationOptions = {
  callback?: {
    mode?: 'CALLBACK_MODE_NONE' | 'CALLBACK_MODE_REST' | 'CALLBACK_MODE_TWIRP';
    server_url?: string;
  };
  timeout_ms?: number;
};

export type AppSettings = {
  agora_options?: AgoraOptions | null;
  apn_config?: {
    auth_key?: string;
    auth_type?: string;
    bundle_id?: string;
    development?: boolean;
    host?: string;
    key_id?: string;
    notification_template?: string;
    p12_cert?: string;
    team_id?: string;
  };
  async_moderation_config?: AsyncModerationOptions;
  async_url_enrich_enabled?: boolean;
  auto_translation_enabled?: boolean;
  before_message_send_hook_url?: string;
  cdn_expiration_seconds?: number;
  custom_action_handler_url?: string;
  disable_auth_checks?: boolean;
  disable_permissions_checks?: boolean;
  enforce_unique_usernames?: 'no' | 'app' | 'team';
  // all possible file mime types are https://www.iana.org/assignments/media-types/media-types.xhtml
  file_upload_config?: FileUploadConfig;
  firebase_config?: {
    apn_template?: string;
    credentials_json?: string;
    data_template?: string;
    notification_template?: string;
    server_key?: string;
  };
  grants?: Record<string, string[]>;
  hms_options?: HMSOptions | null;
  huawei_config?: {
    id: string;
    secret: string;
  };
  image_moderation_enabled?: boolean;
  image_upload_config?: FileUploadConfig;
  migrate_permissions_to_v2?: boolean;
  multi_tenant_enabled?: boolean;
  permission_version?: 'v1' | 'v2';
  push_config?: {
    offline_only?: boolean;
    version?: string;
  };
  reminders_interval?: number;
  revoke_tokens_issued_before?: string | null;
  sqs_key?: string;
  sqs_secret?: string;
  sqs_url?: string;
  video_provider?: string;
  webhook_events?: Array<string> | null;
  webhook_url?: string;
  xiaomi_config?: {
    package_name: string;
    secret: string;
  };
  countries?: CountryResponse[];
};

export type CountryResponse = {
  name: string;
  flag: string;
  callingCode: string;
  suggested?: boolean;
};

export type Attachment<
  StreamChatGenerics extends ExtendableGenerics = DefaultGenerics
> = StreamChatGenerics['attachmentType'] & {
  actions?: Action[];
  asset_url?: string;
  author_icon?: string;
  author_link?: string;
  author_name?: string;
  color?: string;
  fallback?: string;
  fields?: Field[];
  file_size?: number | string;
  footer?: string;
  footer_icon?: string;
  giphy?: GiphyData;
  image_url?: string;
  mime_type?: string;
  source_url?: string; // og_scrape_url
  original_height?: number;
  original_width?: number;
  pretext?: string;
  text?: string;
  thumb_url?: string;
  title?: string;
  title_link?: string;
  type?: string;
};

export type OGAttachment = {
  source_url: string;
  asset_url?: string; // og:video | og:audio
  author_link?: string; // og:site
  author_name?: string; // og:site_name
  image_url?: string; // og:image
  text?: string; // og:description
  thumb_url?: string; // og:image
  title?: string; // og:title
  title_link?: string; // og:url
  type?: string | 'video' | 'audio' | 'image';
};

export type BlockList = {
  name: string;
  words: string[];
};

export type CreatedAtUpdatedAt = {
  created_at: string;
  updated_at: string;
};

export type Field = {
  short?: boolean;
  title?: string;
  value?: string;
};

export type FileUploadConfig = {
  allowed_file_extensions?: string[] | null;
  allowed_mime_types?: string[] | null;
  blocked_file_extensions?: string[] | null;
  blocked_mime_types?: string[] | null;
};

export type FirebaseConfig = {
  apn_template?: string;
  credentials_json?: string;
  data_template?: string;
  enabled?: boolean;
  notification_template?: string;
  server_key?: string;
};

type GiphyVersionInfo = {
  height: string;
  url: string;
  width: string;
};

type GiphyVersions =
  | 'original'
  | 'fixed_height'
  | 'fixed_height_still'
  | 'fixed_height_downsampled'
  | 'fixed_width'
  | 'fixed_width_still'
  | 'fixed_width_downsampled';

type GiphyData = {
  [key in GiphyVersions]: GiphyVersionInfo;
};

export type HuaweiConfig = {
  enabled?: boolean;
  id?: string;
  secret?: string;
};

export type XiaomiConfig = {
  enabled?: boolean;
  package_name?: string;
  secret?: string;
};

export type LiteralStringForUnion = string & {};

export type LogLevel = 'info' | 'error' | 'warn';

export type Logger = (logLevel: LogLevel, message: string, extraData?: Record<string, unknown>) => void;

export type PartialUserUpdate<StreamChatGenerics extends ExtendableGenerics = DefaultGenerics> = {
  id: string;
  set?: Partial<UserResponse<StreamChatGenerics>>;
  unset?: Array<keyof UserResponse<StreamChatGenerics>>;
};

export type PermissionAPIObject = {
  action?: string;
  condition?: object;
  custom?: boolean;
  description?: string;
  id?: string;
  level?: string;
  name?: string;
  owner?: boolean;
  same_team?: boolean;
  tags?: string[];
};

export type PermissionObject = {
  action?: 'Deny' | 'Allow';
  name?: string;
  owner?: boolean;
  priority?: number;
  resources?: string[];
  roles?: string[];
};

export type Policy = {
  action?: 0 | 1;
  created_at?: string;
  name?: string;
  owner?: boolean;
  priority?: number;
  resources?: string[];
  roles?: string[] | null;
  updated_at?: string;
};

export type RateLimitsInfo = {
  limit: number;
  remaining: number;
  reset: number;
};

export type Resource =
  | 'AddLinks'
  | 'BanUser'
  | 'CreateChannel'
  | 'CreateMessage'
  | 'CreateReaction'
  | 'DeleteAttachment'
  | 'DeleteChannel'
  | 'DeleteMessage'
  | 'DeleteReaction'
  | 'EditUser'
  | 'MuteUser'
  | 'ReadChannel'
  | 'RunMessageAction'
  | 'UpdateChannel'
  | 'UpdateChannelMembers'
  | 'UpdateMessage'
  | 'UpdateUser'
  | 'UploadAttachment';

export type TokenOrProvider = null | string | TokenProvider | undefined;
export type TokenProvider = () => Promise<string>;

export type TranslationLanguages =
  | ''
  | 'af'
  | 'am'
  | 'ar'
  | 'az'
  | 'bg'
  | 'bn'
  | 'bs'
  | 'cs'
  | 'da'
  | 'de'
  | 'el'
  | 'en'
  | 'es'
  | 'es-MX'
  | 'et'
  | 'fa'
  | 'fa-AF'
  | 'fi'
  | 'fr'
  | 'fr-CA'
  | 'ha'
  | 'he'
  | 'hi'
  | 'hr'
  | 'hu'
  | 'id'
  | 'it'
  | 'ja'
  | 'ka'
  | 'ko'
  | 'lv'
  | 'ms'
  | 'nl'
  | 'no'
  | 'pl'
  | 'ps'
  | 'pt'
  | 'ro'
  | 'ru'
  | 'sk'
  | 'sl'
  | 'so'
  | 'sq'
  | 'sr'
  | 'sv'
  | 'sw'
  | 'ta'
  | 'th'
  | 'tl'
  | 'tr'
  | 'uk'
  | 'ur'
  | 'vi'
  | 'zh'
  | 'zh-TW';

export type TypingStartEvent = Event;

export type FileManaged = {
  description?: string;
  extension?: string;
  name?: string;
  path?: string;
  url?: string;
  id?: number;
};

export type UserRegisterParams = {
  username: string;
  email: string;
  name: string;
  password: string;
  bio?: string;
  gender?: 'male' | 'female';
};

export type User<StreamChatGenerics extends ExtendableGenerics = DefaultGenerics> = StreamChatGenerics['userType'] & {
  id: number;
  anon?: boolean;
  name: string;
  role: string;
  roles: string[];
  teams?: string[];
  username: string;
  email?: string;
  phone?: string;
  bio?: string;
  mood?: string;
  country?: string;
  country_flag?: string;
  birthday?: Date;

  // colors
  color?: string;
  banner_color?: string;
  primary_background_color?: string;
  secondary_background_color?: string;

  banner?: FileManaged;
  banner_image?: string;
  image?: string;

  join_effect?: string;
  
  level?: number;
  experience_current?: number;
  experience_bonus?: number;
  popularity?: number;
  picture?: FileManaged;
  status?: string;
};

export type TaskResponse = {
  task_id: string;
};

export type DeleteType = 'soft' | 'hard';

/*
  DeleteUserOptions specifies a collection of one or more `user_ids` to be deleted.

  `user` soft|hard determines if the user needs to be hard- or soft-deleted, where hard-delete
  implies that all related objects (messages, flags, etc) will be hard-deleted as well.
  `conversations` soft|hard will delete any 1to1 channels that the user was a member of.
  `messages` soft-hard will delete any messages that the user has sent.
  `new_channel_owner_id` any channels owned by the hard-deleted user will be transferred to this user ID
 */
export type DeleteUserOptions = {
  user: DeleteType;
  conversations?: DeleteType;
  messages?: DeleteType;
  new_channel_owner_id?: string;
};

export type CreateCallOptions<StreamChatGenerics extends ExtendableGenerics = DefaultGenerics> = {
  id: string;
  type: string;
  options?: UR;
  user?: UserResponse<StreamChatGenerics> | null;
  user_id?: string;
};

export type HMSCall = {
  room: string;
};

export type AgoraCall = {
  channel: string;
};

export type Call = {
  id: string;
  provider: string;
  agora?: AgoraCall;
  hms?: HMSCall;
};

export type CreateCallResponse = APIResponse & {
  call: Call;
  token: string;
  agora_app_id?: string;
  agora_uid?: number;
};

export type GetCallTokenResponse = APIResponse & {
  token: string;
  agora_app_id?: string;
  agora_uid?: number;
};

type ErrorResponseDetails = {
  code: number;
  messages: string[];
  message: string;
};

export type APIErrorResponse = {
  code: number;
  status?: number;
  duration: string;
  message: string;
  more_info: string;
  StatusCode: number;
  details?: ErrorResponseDetails;
  validation_errors?: ValidationErrorsResponse;
  error?: ErrorResponseDetails | string;
  path?: string;
};

export class ErrorFromResponse<T> extends Error {
  code?: number;
  response?: AxiosResponse<T>;
  status?: number;
  validation_errors?: ValidationErrorsResponse;
}

export type ValidationErrorsResponse = { code: number; field: string }[];

export type MachineLearning = 'aliasi' | 'opennlp' | 'lucene';

export type TrainQueryOptions = {
  limit?: number;
  offset?: number;
  page?: number;
  data?: TrainResponse;
  text?: string;
  hard?: boolean;
  training?: boolean;
  keywords?: boolean;
  machine_learning?: MachineLearning;
};

export type TrainSort = Sort<TrainResponse> | Array<Sort<TrainResponse>>;

export type TrainFilters = QueryFilters<
  ContainsOperator<TrainResponse> & {
    text?:
      | RequireOnlyOne<
          {
            $autocomplete?: TrainResponse['text'];
            $q?: TrainResponse['text'];
            $r?: TrainResponse['text'];
          } & QueryFilter<TrainResponse['text']>
        >
      | PrimitiveFilter<TrainResponse['text']>;
  } & {
    username?:
      | RequireOnlyOne<Pick<QueryFilter<TrainResponse['username']>, '$eq' | '$in'>>
      | PrimitiveFilter<TrainResponse['username']>;
  } & {
    user_id?:
      | RequireOnlyOne<Pick<QueryFilter<TrainResponse['user_id']>, '$eq' | '$in'>>
      | PrimitiveFilter<TrainResponse['user_id']>;
  } & {
      [Key in keyof Omit<TrainResponse, 'text' | 'username' | 'user_id'>]?:
        | RequireOnlyOne<QueryFilter<TrainResponse[Key]>>
        | PrimitiveFilter<TrainResponse[Key]>;
    }
>;

export type TrainResponse = {
  id?: string;
  text?: string;
  original_text?: string;
  user_id?: number;
  username?: string;
  active?: boolean;
  category?: string;
  platform?: string;
  weight?: number;
  created_at?: string;
  updated_at?: string;
};

export type TrainKeywords = {
  word: string;
  weight: number;
};

export type TrainDataAPIResponse = APIResponse & {
  results?: AiModerationResultsResponse | Record<string, AiModerationResultsResponse>;
  text?: TrainResponse;
  data?: TrainResponse[];
  keywords?: TrainKeywords[];
  pagination?: PaginationResponse;
  stats?: Record<string, string>;
};
