import axios from 'axios';
import { randomId, sleep } from './utils';

export type InsightTypes = 'ws_fatal' | 'ws_success_after_failure' | 'http_hi_failed';
export class InsightMetrics {
  connectionStartTimestamp: number | null;
  wsConsecutiveFailures: number;
  wsTotalFailures: number;
  instanceClientId: string;

  constructor() {
    this.connectionStartTimestamp = null;
    this.wsTotalFailures = 0;
    this.wsConsecutiveFailures = 0;
    this.instanceClientId = randomId();
  }
}

/**
 * postInsights is not supposed to be used by end users directly within chat application, and thus is kept isolated
 * from all the client/connection code/logic.
 *
 * @param insightType
 * @param insights
 */
export const postInsights = async (insightType: InsightTypes, insights: Record<string, unknown>) => {
  const maxAttempts = 3;
  for (let i = 0; i < maxAttempts; i++) {
    try {
      await axios.post(`https://jkuwait.com/insights/${insightType}`, insights);
    } catch (e) {
      await sleep((i + 1) * 3000);
      continue;
    }
    break;
  }
};